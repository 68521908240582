import {OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";

export abstract class UnsubscribeOnDestroy implements OnDestroy {

  protected subs: Subscription[] = [];

  protected constructor() {
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
  }
}
