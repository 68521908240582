<clr-select-container>
  <select clrSelect
          [formControl]="formControl"
          [formlyAttributes]="field">
    <option *ngFor="let item of to.options"
            [value]="item.value"
            [disabled]="item.disabled">
      {{ item.label }}
    </option>
  </select>

  <clr-control-helper *ngIf="to.placeholder">{{ to.placeholder }}</clr-control-helper>
</clr-select-container>
