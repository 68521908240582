var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FieldType } from "@ngx-formly/core";
var ClarityMulticheckboxComponent = /** @class */ (function (_super) {
    __extends(ClarityMulticheckboxComponent, _super);
    function ClarityMulticheckboxComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClarityMulticheckboxComponent.prototype.onChange = function (value, checked) {
        this.formControl.patchValue(checked
            ? (this.formControl.value || []).concat([value]) : (this.formControl.value || []).slice().filter(function (o) { return o !== value; }));
        this.formControl.markAsTouched();
    };
    Object.defineProperty(ClarityMulticheckboxComponent.prototype, "checked", {
        get: function () {
            return;
        },
        enumerable: true,
        configurable: true
    });
    return ClarityMulticheckboxComponent;
}(FieldType));
export { ClarityMulticheckboxComponent };
