import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Mutex} from "async-mutex";
import {RecordRepository} from "../repositories/record.repository";
import Record from "../../../../../database/models/record";
import {listToObject} from "../../shared/util/util";
import {DeviceRepository} from "../repositories/device.repository";
import {LocalDbService} from "./database/local-db.service";
import {FormSubmissionRepository} from "../repositories/form-submission.repository";
import FormSubmission from "../../../../../database/models/form-submission";

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache: {
    [tag: string]: {
      latest: number,
      models: any[]
    };
  } = {};
  private mutex = new Mutex();

  constructor(
    private recordRepository: RecordRepository,
    private deviceRepository: DeviceRepository,
    private formSubmissionRepository: FormSubmissionRepository,
    private localdb: LocalDbService
  ) {
  }

  private _registering$ = new BehaviorSubject<number>(null);

  get registering$() {
    return this._registering$.asObservable();
  }

  async get<T>(tag: string, getTimestamp: (model: T) => number, fetch: (latest: number) => Promise<T[]>): Promise<T[]> {

    const release = await this.mutex.acquire();

    const models = await fetch(this.cache[tag].latest);

    if (!!models && models.length > 0) {
      this.cache[tag].models.push(...models);
      this.cache[tag].latest = getTimestamp(models[models.length - 1]);
    }

    release();

    return this.cache[tag].models;
  }

  async init() {
    await this.initRecords();
    await this.initFormSubmissions();
  }

  async register<T>(tag: string, getTimestamp: (model: T) => number, fetch: () => Promise<T[]>): Promise<void> {

    console.log(tag);
    this._registering$.next(this._registering$.value + 1);

    const models = await fetch();

    let latest = 0;

    if (models.length > 0) {
      latest = getTimestamp(models[models.length - 1]);
    }

    this.cache[tag] = {
      latest: latest,
      models: models
    };

    this._registering$.next(this._registering$.value - 1);
  }

  private async initFormSubmissions() {

    await this.register<FormSubmission>('formSubmissions', model => model.updated, () => {
      return this.formSubmissionRepository.list(null, 'updated');
    });

    this.formSubmissionRepository.cachedList = async tag => {
      switch (tag) {
        case 'formSubmissions':
          return await this.get<FormSubmission>('formSubmissions', model => model.updated, latest => {
            return this.localdb.between<FormSubmission>("formSubmissions", "updated", latest)
          });
      }
    }
  }

  private async initRecords() {
    await this.register<Record>('records', model => model.timestamp, () => {
      return this.recordRepository.list(null, 'timestamp').then(async models => {

        const devices = listToObject(await this.deviceRepository.list(), '$id');

        for (const record of models) {
          if (!!record.values && !record.values['C1']) {
            RecordRepository.calculateRecord(record, devices[record.deviceId])
          }
        }

        return models;
      });
    });

    this.recordRepository.cachedList = async tag => {
      switch (tag) {
        case 'records':
          return await this.get<Record>('records', model => model.timestamp, latest => {
            return this.localdb.between<Record>("records", "timestamp", latest)
              .then(async records => {
                const devices = listToObject(await this.deviceRepository.list(), '$id');

                for (const record of records) {
                  if (!!record.values && !record.values['C1']) {
                    RecordRepository.calculateRecord(record, devices[record.deviceId])
                  }
                }

                return records;
              });
          });
      }
    }
  }
}
