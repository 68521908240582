import {Component} from '@angular/core';
import {FieldType} from "@ngx-formly/core";

@Component({
  selector: 'app-clarity-multicheckbox',
  templateUrl: './clarity-multicheckbox.component.html',
  styleUrls: ['./clarity-multicheckbox.component.scss']
})
export class ClarityMulticheckboxComponent extends FieldType {

  onChange(value, checked) {
    this.formControl.patchValue(checked
      ? [...(this.formControl.value || []), value]
      : [...(this.formControl.value || [])].filter(o => o !== value),
    );
    this.formControl.markAsTouched();
  }

  get checked() {
    return
  }
}
