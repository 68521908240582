import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {UnsubscribeOnDestroy} from "../../protected/unsubscribe-on-destroy";
import {OfflineService} from "../../protected/services/offline.service";
import {LocalDbService} from "../../protected/services/database/local-db.service";
import {filter, map} from "rxjs/operators";
import {AngularFireAuth} from "@angular/fire/auth";
import {UserRepository} from "../../protected/repositories/user.repository";
import User from "../../../../../database/models/user";
import {ApplicationSettingRepository} from "../../protected/repositories/application-setting.repository";
import {asPromise} from "../../shared/util/rxjs.util";
import ClaimsUtil from "../../shared/util/claims.util";
import {RemoteDbService} from "../../protected/services/database/remote-db.service";
import {ApmService} from "./apm.service";
import * as firebase from "firebase";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends UnsubscribeOnDestroy {

  constructor(
    private userRepository: UserRepository,
    private offlineService: OfflineService,
    private fireAuth: AngularFireAuth,
    private localdb: LocalDbService,
    private remotedb: RemoteDbService,
    private applicationSettingRepository: ApplicationSettingRepository,
    private apmService: ApmService
  ) {
    super();
  }

  private _uid$: BehaviorSubject<string> = new BehaviorSubject(null);

  private get uid$(): Observable<string> {
    return this._uid$.pipe(filter(f => !!f))
  }

  static redirectToApp() {
    window.location.replace('/app');
  }

  static redirectToLogin() {
    window.location.replace('/login');
  }

  authUser(): firebase.User {
    return this.fireAuth.auth.currentUser;
  }

  async authenticated(): Promise<boolean> {
    return !!(await this.uid());
  }

  getToken(): Promise<string> {
    return asPromise(this.fireAuth.idToken);
  }

  async refreshClaims() {
    const tokenResult = await asPromise(this.fireAuth.idTokenResult);
    const user = await this.user();
    user.claims = tokenResult.claims;
    await this.userRepository.save(user);
  }

  async signIn() {

    const authUser = await asPromise(this.fireAuth.authState);

    const tokenResult = await asPromise(this.fireAuth.idTokenResult);

    if (ClaimsUtil.deviceIds(tokenResult.claims).length == 0) {
      await this.fireAuth.auth.signOut();
      throw new Error("You are not registered for this service");
    }

    await this.applicationSettingRepository.save({
      $id: 'uid',
      value: authUser.uid
    });

    let user = await this.remotedb.object<User>(`admin/users/${authUser.uid}`);
    await this.userRepository.save(user);
    console.log({message: `Signed in`, user: user, authUser: authUser});
    this.apmService.setUser(authUser.uid, user.firstName + ' ' + user.lastName, user.mobile);
    this._uid$.next(authUser.uid);
  }

  async signOut() {
    await this.fireAuth.auth.signOut();
    await this.applicationSettingRepository.remove('uid');
    this._uid$.next(null);
    await AuthService.redirectToLogin();
  }

  async user(): Promise<User> {
    const uid = await this.uid();

    if (!uid) {

      return null;
    }

    let user: User = await this.userRepository.get(uid);

    if (!!user) {
      return user;
    }

    user = await this.remotedb.object<User>(`admin/users/${uid}`);
    await this.userRepository.update(user);
    return user;
  }

  private async uid(): Promise<string> {

    const applicationSetting = await this.applicationSettingRepository.get('uid');

    let uid = !!applicationSetting ? applicationSetting.value : null;

    if (!!uid) {
      return uid;
    }

    uid = await asPromise(this.fireAuth.authState.pipe(
      map(user => !!user ? user.uid : null)
    ));

    await this.applicationSettingRepository.save({
      $id: 'uid',
      value: uid
    });

    return uid;
  }
}
