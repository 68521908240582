import {Injectable} from '@angular/core';
import Record from "../../../../../database/models/record"
import {LocalDbService} from "../services/database/local-db.service";
import AbstractRepository from "./abstract.repository";

@Injectable({
  providedIn: 'root'
})
export class RecordRepository extends AbstractRepository<Record> {

  constructor(
    localdb: LocalDbService
  ) {
    super(localdb, "records");
  }

  static calculate(record, calculation): number {

    switch (calculation.type) {
      case 'WET_BULB': {
        if (!!record.values) {
          const dryBulb = record.values[calculation.inputs[0]];
          const rh = record.values[calculation.inputs[1]];

          return dryBulb * Math.atan(0.151977 * Math.sqrt(rh + 8.313659)) + Math.atan(dryBulb + rh)
            - Math.atan(rh - 1.676331) + 0.00391838 * Math.pow(Math.sqrt(rh), 3) * Math.atan(0.023101 * rh)
            - 4.686035;
        }
      }
    }

    return 0;
  }


  static calculateRecord(record, device) {

    for (const sensorId of Object.keys(record.values)) {
      const value = record.values[sensorId];
      if (value < 0) {
        record.values[sensorId] = 0
      }
    }
    for (const calcId in device.calculations) {
      record.values[calcId] = RecordRepository.calculate(record, device.calculations[calcId]);
    }
  }

  async byDeviceId(deviceId: string): Promise<Record[]> {

    let records = await this.cachedList('records');

    return records.filter(a => a.deviceId === deviceId);
  }

  async lastByDeviceId(deviceId: string): Promise<Record> {

    const records = await this.byDeviceId(deviceId);

    if (!!records && records.length > 0) {
      return records[records.length - 1];
    }

    return null;
  }

  async sliceByDeviceId(deviceId: string, start: number, end?: number): Promise<Record[]> {
    const records = await this.byDeviceId(deviceId);
    return records.filter(record => (record.timestamp >= start) && (!end || (record.timestamp < end)))
  }
}
