export default class ClaimsUtil {

  static hasAnyPermission(claims: any, deviceId: string, permissions?: string[]): boolean {

    // User has claims to no devices, return false
    if (!claims || !claims.devices || !claims[deviceId]) {
      return false;
    }

    // If no permissions given, just check if there are any claims on the device at all
    if (!permissions || permissions.length == 0) {
      // We have already checked above whether there are claims for this device, so return true
      return true;
    }

    for (const permission of permissions) {
      if (claims.devices[deviceId].includes(permission)) {
        return true;
      }
    }

    return false;
  }

  static deviceIds(claims: any): string[] {
    if (!claims || !claims.devices) {
      return [];
    }

    return Object.keys(claims.devices);
  }

  static permissions(claims: any): {[deviceId: string]: {[permission: string]: boolean}} {
    const deviceIds = ClaimsUtil.deviceIds(claims);
    const permissions = {};
    for (const deviceId of deviceIds) {
      permissions[deviceId] = ClaimsUtil.forDevice(claims, deviceId)
    }
    return permissions;
  }

  static forDevice(claims: any, deviceId: string): { [permission: string]: boolean } {
    if (!claims.devices[deviceId]) {
      return {};
    }

    const claimsList = claims.devices[deviceId].split('|');

    const claimsObject: { [permission: string]: boolean } = {};

    for (const claim of claimsList) {
      claimsObject[claim] = true;
    }
    return claimsObject;
  }
}
