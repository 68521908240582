import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AuthService} from '../../core/services/auth.service';
import {FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult} from "firebaseui-angular";
import {UnsubscribeOnDestroy} from "../../protected/unsubscribe-on-destroy";
import {asPromise} from "../../shared/util/rxjs.util";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends UnsubscribeOnDestroy implements OnInit {
  loadingMessage: string = "Loading...";
  errorMessage: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  async ngOnInit() {
    const authenticated = await this.authService.authenticated();

    if (authenticated) {
      AuthService.redirectToApp();
    }

    this.loadingMessage = null;
    if (!this.changeDetector['destroyed']) {
      this.changeDetector.detectChanges();
    }
  }

  async successCallback($event: FirebaseUISignInSuccessWithAuthResult) {
    try {
      await this.authService.signIn();
      await this.router.navigate(['app']);
      if (!this.changeDetector['destroyed']) {
        this.changeDetector.detectChanges();
      }
    } catch (e) {
      this.errorMessage = e.message;
    }
  }

  errorCallback($event: FirebaseUISignInFailure) {
    this.errorMessage = `Failed to sign in. Error code: ${$event.code}`
  }
}
