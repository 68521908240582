import {Injector, NgModule} from '@angular/core';
import {RouterModule} from "@angular/router";
import {ServiceWorkerModule} from '@angular/service-worker';
import {Angulartics2Module} from 'angulartics2';
import {environment} from '../environments/environment';

import {AppComponent} from './app.component';
import {CoreModule} from "./core/core.module";
import {PublicModule} from "./public/public.module";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

export let AppInjector: Injector;

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CoreModule,
    PublicModule,
    RouterModule,
    ServiceWorkerModule.register(environment.production ? 'sw-master.js' : 'sw-sync.js'),
    Angulartics2Module.forRoot(),
    BrowserAnimationsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}
