import {CommonModule} from '@angular/common';
import {HttpClientModule} from "@angular/common/http";
import {NgModule} from '@angular/core';
import {AngularFireModule} from "@angular/fire";
import {AngularFireAuth, AngularFireAuthModule} from "@angular/fire/auth";
import {AngularFireDatabaseModule} from "@angular/fire/database";
import {AngularFireMessagingModule} from "@angular/fire/messaging";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ClarityModule} from "@clr/angular";
import {FormlyModule} from "@ngx-formly/core";
import {environment} from "../../environments/environment";
import {AuthGuardService} from "./services/auth-guard.service";
import {AuthService} from "./services/auth.service";
import {FirebaseUIModule} from "firebaseui-angular";
import * as firebaseui from "firebaseui";
import * as firebase from "firebase/app";
import {RouteReuseStrategy} from "@angular/router";
import {CustomRouteReuseStrategy} from "../protected/custom-route-reuse-strategy";
import { DeviceDetectorModule } from 'ngx-device-detector';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInOptions: [{
    provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    recaptchaParameters: {
      size: "invisible"
    },
    defaultCountry: "ZW",
  }]
};

@NgModule({
  imports: [
    AngularFireModule.initializeApp(environment.firebase, 'cognosc'),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormlyModule.forRoot(),
    DeviceDetectorModule.forRoot()
  ],
  declarations: [],
  providers: [
    AngularFireAuth,
    AuthService,
    AuthGuardService,
    {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy}

  ],
  exports: [
    ClarityModule,
    CommonModule
  ]
})
export class CoreModule {
}
