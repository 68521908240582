var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject } from "rxjs";
import { UnsubscribeOnDestroy } from "../../protected/unsubscribe-on-destroy";
import { OfflineService } from "../../protected/services/offline.service";
import { LocalDbService } from "../../protected/services/database/local-db.service";
import { filter, map } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/auth";
import { UserRepository } from "../../protected/repositories/user.repository";
import { ApplicationSettingRepository } from "../../protected/repositories/application-setting.repository";
import { asPromise } from "../../shared/util/rxjs.util";
import ClaimsUtil from "../../shared/util/claims.util";
import { RemoteDbService } from "../../protected/services/database/remote-db.service";
import { ApmService } from "./apm.service";
import * as i0 from "@angular/core";
import * as i1 from "../../protected/repositories/user.repository";
import * as i2 from "../../protected/services/offline.service";
import * as i3 from "@angular/fire/auth";
import * as i4 from "../../protected/services/database/local-db.service";
import * as i5 from "../../protected/services/database/remote-db.service";
import * as i6 from "../../protected/repositories/application-setting.repository";
import * as i7 from "./apm.service";
var AuthService = /** @class */ (function (_super) {
    __extends(AuthService, _super);
    function AuthService(userRepository, offlineService, fireAuth, localdb, remotedb, applicationSettingRepository, apmService) {
        var _this = _super.call(this) || this;
        _this.userRepository = userRepository;
        _this.offlineService = offlineService;
        _this.fireAuth = fireAuth;
        _this.localdb = localdb;
        _this.remotedb = remotedb;
        _this.applicationSettingRepository = applicationSettingRepository;
        _this.apmService = apmService;
        _this._uid$ = new BehaviorSubject(null);
        return _this;
    }
    Object.defineProperty(AuthService.prototype, "uid$", {
        get: function () {
            return this._uid$.pipe(filter(function (f) { return !!f; }));
        },
        enumerable: true,
        configurable: true
    });
    AuthService.redirectToApp = function () {
        window.location.replace('/app');
    };
    AuthService.redirectToLogin = function () {
        window.location.replace('/login');
    };
    AuthService.prototype.authUser = function () {
        return this.fireAuth.auth.currentUser;
    };
    AuthService.prototype.authenticated = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uid()];
                    case 1: return [2 /*return*/, !!(_a.sent())];
                }
            });
        });
    };
    AuthService.prototype.getToken = function () {
        return asPromise(this.fireAuth.idToken);
    };
    AuthService.prototype.refreshClaims = function () {
        return __awaiter(this, void 0, void 0, function () {
            var tokenResult, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, asPromise(this.fireAuth.idTokenResult)];
                    case 1:
                        tokenResult = _a.sent();
                        return [4 /*yield*/, this.user()];
                    case 2:
                        user = _a.sent();
                        user.claims = tokenResult.claims;
                        return [4 /*yield*/, this.userRepository.save(user)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.signIn = function () {
        return __awaiter(this, void 0, void 0, function () {
            var authUser, tokenResult, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, asPromise(this.fireAuth.authState)];
                    case 1:
                        authUser = _a.sent();
                        return [4 /*yield*/, asPromise(this.fireAuth.idTokenResult)];
                    case 2:
                        tokenResult = _a.sent();
                        if (!(ClaimsUtil.deviceIds(tokenResult.claims).length == 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.fireAuth.auth.signOut()];
                    case 3:
                        _a.sent();
                        throw new Error("You are not registered for this service");
                    case 4: return [4 /*yield*/, this.applicationSettingRepository.save({
                            $id: 'uid',
                            value: authUser.uid
                        })];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.remotedb.object("admin/users/" + authUser.uid)];
                    case 6:
                        user = _a.sent();
                        return [4 /*yield*/, this.userRepository.save(user)];
                    case 7:
                        _a.sent();
                        console.log({ message: "Signed in", user: user, authUser: authUser });
                        this.apmService.setUser(authUser.uid, user.firstName + ' ' + user.lastName, user.mobile);
                        this._uid$.next(authUser.uid);
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.signOut = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fireAuth.auth.signOut()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.applicationSettingRepository.remove('uid')];
                    case 2:
                        _a.sent();
                        this._uid$.next(null);
                        return [4 /*yield*/, AuthService.redirectToLogin()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.user = function () {
        return __awaiter(this, void 0, void 0, function () {
            var uid, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uid()];
                    case 1:
                        uid = _a.sent();
                        if (!uid) {
                            return [2 /*return*/, null];
                        }
                        return [4 /*yield*/, this.userRepository.get(uid)];
                    case 2:
                        user = _a.sent();
                        if (!!user) {
                            return [2 /*return*/, user];
                        }
                        return [4 /*yield*/, this.remotedb.object("admin/users/" + uid)];
                    case 3:
                        user = _a.sent();
                        return [4 /*yield*/, this.userRepository.update(user)];
                    case 4:
                        _a.sent();
                        return [2 /*return*/, user];
                }
            });
        });
    };
    AuthService.prototype.uid = function () {
        return __awaiter(this, void 0, void 0, function () {
            var applicationSetting, uid;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.applicationSettingRepository.get('uid')];
                    case 1:
                        applicationSetting = _a.sent();
                        uid = !!applicationSetting ? applicationSetting.value : null;
                        if (!!uid) {
                            return [2 /*return*/, uid];
                        }
                        return [4 /*yield*/, asPromise(this.fireAuth.authState.pipe(map(function (user) { return !!user ? user.uid : null; })))];
                    case 2:
                        uid = _a.sent();
                        return [4 /*yield*/, this.applicationSettingRepository.save({
                                $id: 'uid',
                                value: uid
                            })];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, uid];
                }
            });
        });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.UserRepository), i0.inject(i2.OfflineService), i0.inject(i3.AngularFireAuth), i0.inject(i4.LocalDbService), i0.inject(i5.RemoteDbService), i0.inject(i6.ApplicationSettingRepository), i0.inject(i7.ApmService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}(UnsubscribeOnDestroy));
export { AuthService };
