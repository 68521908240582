var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { AngularFireDatabase } from "@angular/fire/database";
import { UnsubscribeOnDestroy } from "../unsubscribe-on-destroy";
import { distinctUntilChanged, filter } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/database";
var OfflineService = /** @class */ (function (_super) {
    __extends(OfflineService, _super);
    function OfflineService(firedb) {
        var _this = _super.call(this) || this;
        _this.firedb = firedb;
        _this._offline$ = new BehaviorSubject(undefined);
        _this.subs.push(_this.firedb.object(".info/connected").valueChanges()
            .subscribe(function (connected) {
            _this._offline$.next(!connected);
        }));
        return _this;
    }
    Object.defineProperty(OfflineService.prototype, "offline$", {
        get: function () {
            return this._offline$.asObservable().pipe(filter(function (offline) { return offline != undefined; }));
        },
        enumerable: true,
        configurable: true
    });
    OfflineService.prototype.whenOnline = function (subscriber) {
        this.subs.push(this.offline$.pipe(distinctUntilChanged(), filter(function (a) { return !a; }))
            .subscribe(function () { return subscriber(); }));
    };
    OfflineService.ngInjectableDef = i0.defineInjectable({ factory: function OfflineService_Factory() { return new OfflineService(i0.inject(i1.AngularFireDatabase)); }, token: OfflineService, providedIn: "root" });
    return OfflineService;
}(UnsubscribeOnDestroy));
export { OfflineService };
