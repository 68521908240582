import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UserRepository} from "../../protected/repositories/user.repository";
import {AuthService} from "./auth.service";
import {CacheService} from "../../protected/services/cache.service";
import {asPromise} from "../../shared/util/rxjs.util";

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(private router: Router,
              private userService: UserRepository,
              private authService: AuthService,
              private cacheService: CacheService) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.performCheck(next, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.performCheck(childRoute, state);
  }

  private async performCheck(next: ActivatedRouteSnapshot,
                             state: RouterStateSnapshot): Promise<boolean> {

    const user = await this.authService.user();

    if (!user) {
      await this.router.navigate(['/login']);
      return false;
    }

    const registering = await asPromise(this.cacheService.registering$);

    if ((registering === null) || (registering > 0)) {
      return this.router.navigate(['/app/loader'], {queryParams: {returnUrl: state.url}});
    }
    // if (!!next.routeConfig) {
    //   switch (next.routeConfig.path) {
    //     case "setup":
    //       return hasAnyPermission(['device-write']);
    //     case "barcodes":
    //       return hasAnyPermission(['barcodes-read', 'barcodes-write']);
    //   }
    // }

    return true;
  }
}
