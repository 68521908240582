import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {ClarityModule} from "@clr/angular";
import {FormlyModule} from "@ngx-formly/core";
import {ModalFormComponent} from "./modal-form/modal-form.component";
import {FormlyBootstrapModule} from '@ngx-formly/bootstrap';
import { ClarityMulticheckboxComponent } from './clarity-multicheckbox/clarity-multicheckbox.component';
import { ClaritySelectComponent } from './clarity-select/clarity-select.component';
import {PapaParseModule} from "ngx-papaparse";

export const formlyConfig = {
  types: [
    {name: 'multicheckbox', component: ClarityMulticheckboxComponent},
    {name: 'select', component: ClaritySelectComponent},
  ]
};
@NgModule({
  imports: [
    ClarityModule,
    CommonModule,
    FormlyModule.forChild(formlyConfig),
    ReactiveFormsModule,
    FormlyBootstrapModule,
    PapaParseModule
  ],
  declarations: [
    ModalFormComponent,
    ClarityMulticheckboxComponent,
    ClaritySelectComponent,
  ],
  exports: [
    ClarityModule,
    CommonModule,
    ModalFormComponent,
    ReactiveFormsModule,
    ClarityMulticheckboxComponent,
    ClaritySelectComponent
  ]
})
export class SharedModule {

  public static forRoot(): ModuleWithProviders[] {
    return [
      {ngModule: SharedModule, providers: []},
      FormlyModule.forRoot(),
    ];
  }

  public static forChild(): ModuleWithProviders[] {
    return [
      {ngModule: SharedModule, providers: []},
      FormlyModule.forChild(formlyConfig),
    ];
  }
}
