import {Injectable} from '@angular/core';
import FormSubmission from "../../../../../database/models/form-submission";
import {FilterType, LocalDbService} from "../services/database/local-db.service";
import AbstractRepository from "./abstract.repository";
import * as moment from "moment";
import {SyncService} from "../services/sync.service";

@Injectable({
  providedIn: 'root'
})
export class FormSubmissionRepository extends AbstractRepository<FormSubmission> {

  constructor(
    localdb: LocalDbService,
    syncService: SyncService
  ) {
    super(localdb, "formSubmissions", syncService, {shouldUpload: true});
  }

  static fieldValue(formSubmission: FormSubmission, field: any) {
    if ((!formSubmission) || (!formSubmission.fields)) {
      return undefined;
    }
    const fieldValue = formSubmission.fields[field.key];

    switch (field.type) {
      case "select":
        if (!formSubmission.fields[field.key]) {
          return undefined;
        }
        const option = field.templateOptions.options.find(o => o.value == fieldValue);
        if (!option) {
          return fieldValue;
        }
        return option.label;
      case "input":
        if (!!field.templateOptions.type) {
          switch (field.templateOptions.type) {
            case "date":
              if (!fieldValue) {
                return fieldValue;
              }
              return moment.unix(fieldValue).format("DD/MM/YYYY")
          }
        }
        return formSubmission.fields[field.key];

      default:
        return formSubmission.fields[field.key];
    }
  }


  async listFromCache(orderBy?: string) {
      let formSubmissions = await this.cachedList('formSubmissions');
      if (!!orderBy) {
        return formSubmissions.sort( (a, b) => a[orderBy] - b[orderBy])
      }
  }

  // async list(filter?: FilterType, orderBy?: string): Promise<FormSubmission[]> {
  //   let formSubmissions = await this.cachedList('formSubmissions');
  //   if (!!orderBy) {
  //     return formSubmissions.sort( (a, b) => a[orderBy] - b[orderBy])
  //   }
  // }

  async byFormId(deviceId: string, formId: string): Promise<FormSubmission[]> {

    const formSubmissions = await this.cachedList('formSubmissions');

    return formSubmissions.filter(a => a.deviceId === deviceId && a.formId === formId);
  }

  async byFormNumber(deviceId: string, formNumber: string): Promise<FormSubmission[]> {
    return await super.list({deviceId: deviceId, number: formNumber});
  }
}
