import * as moment from "moment";

export function objectToMap(object: any): Map<string, any> {
  const map = new Map<string, any>();
  for (const id in object) {
    map.set(id, object);
  }
  return map;
}

export function arraySum<T>(data: T[], value: (T) => number) {
  let sum = 0;
  for (const datum of data) {
    let v = value(datum);
    if (isNaN(v)) {
      v = 0;
    }
    sum += v;
  }
  return sum;
}

export function arrayHistogram<T>(data: T[], width: number, timestamp: (next: T) => number, map: (next: T, value: number, count: number) => number, cumulative?: boolean) {

  const hist = [];

  let curr: any;

  for (let next of data) {

    if (!curr || (timestamp(next) - curr.timestamp) > width) {

      const ts = timestamp(next);
      if (!!curr && cumulative) {

        curr = {
          timestamp: ts - (ts % width),
          value: curr.value,
          count: curr.count
        };
      } else {
        curr = {
          timestamp: ts - (ts % width),
          value: 0,
          count: 0
        };
      }
      hist.push(curr);
    }

    curr.count++;
    curr.value = map(next, curr.value, curr.count);
  }

  return hist;
}

export function arrayMove(array: any[], from, to) {
  return from >= to ? array.splice(to, 0, array.splice(from, 1)[0])
    : array.splice(to - 1, 0, array.splice(from, 1)[0]);
}

export function arrayRemoveIf<T>(array: T[], callback: (T, number) => boolean): void {
  let i = array.length;
  while (i--) {
    if (callback(array[i], i)) {
      array.splice(i, 1);
    }
  }
}

export function padLeft(text: string, padChar: string, size: number): string {
  return (String(padChar).repeat(size) + text).substr((size * -1), size);
}

export function shallowClone(value: any) {
  if (typeof value === 'object') {
    return {...value};
  }

  return value;
}

export function objectToList<T>(object: { [id: string]: T }, idField?: string): T[] {
  if (idField == undefined) {
    idField = '$id';
  }
  const list: T[] = [];
  if (!object) {
    return list;
  }
  Object.keys(object).forEach(function (key) {
    let model = object[key];
    model[idField] = key;
    list.push(model)
  });

  return list;
}

export function setIds(items) {
  Object.keys(items).forEach(k => {
    items[k].$id = k;
  })
}

export function listToObject<T>(list: T[], idField: string): { [id: string]: T } {
  const object = {};
  for (const model of list) {
    object[model[idField]] = model;
  }
  return object;
}

export function groupListBy<T>(list: T[], fieldName: string): { [fieldValue: string]: T[] } {

  return list.reduce((grouped, next) => {
    if (!grouped[next[fieldName]]) {
      grouped[next[fieldName]] = [];
    }
    grouped[next[fieldName]].push(next);
    return grouped;
  }, {})
}

export function groupListByAndTakeLast<T>(list: T[], fieldName: string): { [fieldValue: string]: T } {

  return list.reduceRight((grouped, next) => {
    if (!grouped[next[fieldName]]) {
      grouped[next[fieldName]] = next;
    }
    return grouped;
  }, {})
}

export function formatDate(value: number, format?: string): string {
  if (!value) {
    return undefined;
  }
  return moment.unix(value).format(format);
}

export function arrayLast<T>(array: T[]): T {
  if (!array || (array.length == 0)) {
    return undefined;
  }
  return array[array.length - 1];
}

export function formatNumber(value: number, digits?: number) {
  if (digits == undefined) {
    digits = 0;
  }
  if (isNaN(value)) {
    value = 0;
  }
  return Intl.NumberFormat('en-zw', {minimumFractionDigits: digits}).format(+(+value).toFixed(digits))
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function max<T>(array: T[], map: (T) => number) {

  return Math.max(...array.map(map))
}
