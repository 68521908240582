var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as moment from "moment";
export function objectToMap(object) {
    var map = new Map();
    for (var id in object) {
        map.set(id, object);
    }
    return map;
}
export function arraySum(data, value) {
    var sum = 0;
    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
        var datum = data_1[_i];
        var v = value(datum);
        if (isNaN(v)) {
            v = 0;
        }
        sum += v;
    }
    return sum;
}
export function arrayHistogram(data, width, timestamp, map, cumulative) {
    var hist = [];
    var curr;
    for (var _i = 0, data_2 = data; _i < data_2.length; _i++) {
        var next = data_2[_i];
        if (!curr || (timestamp(next) - curr.timestamp) > width) {
            var ts = timestamp(next);
            if (!!curr && cumulative) {
                curr = {
                    timestamp: ts - (ts % width),
                    value: curr.value,
                    count: curr.count
                };
            }
            else {
                curr = {
                    timestamp: ts - (ts % width),
                    value: 0,
                    count: 0
                };
            }
            hist.push(curr);
        }
        curr.count++;
        curr.value = map(next, curr.value, curr.count);
    }
    return hist;
}
export function arrayMove(array, from, to) {
    return from >= to ? array.splice(to, 0, array.splice(from, 1)[0])
        : array.splice(to - 1, 0, array.splice(from, 1)[0]);
}
export function arrayRemoveIf(array, callback) {
    var i = array.length;
    while (i--) {
        if (callback(array[i], i)) {
            array.splice(i, 1);
        }
    }
}
export function padLeft(text, padChar, size) {
    return (String(padChar).repeat(size) + text).substr((size * -1), size);
}
export function shallowClone(value) {
    if (typeof value === 'object') {
        return __assign({}, value);
    }
    return value;
}
export function objectToList(object, idField) {
    if (idField == undefined) {
        idField = '$id';
    }
    var list = [];
    if (!object) {
        return list;
    }
    Object.keys(object).forEach(function (key) {
        var model = object[key];
        model[idField] = key;
        list.push(model);
    });
    return list;
}
export function setIds(items) {
    Object.keys(items).forEach(function (k) {
        items[k].$id = k;
    });
}
export function listToObject(list, idField) {
    var object = {};
    for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
        var model = list_1[_i];
        object[model[idField]] = model;
    }
    return object;
}
export function groupListBy(list, fieldName) {
    return list.reduce(function (grouped, next) {
        if (!grouped[next[fieldName]]) {
            grouped[next[fieldName]] = [];
        }
        grouped[next[fieldName]].push(next);
        return grouped;
    }, {});
}
export function groupListByAndTakeLast(list, fieldName) {
    return list.reduceRight(function (grouped, next) {
        if (!grouped[next[fieldName]]) {
            grouped[next[fieldName]] = next;
        }
        return grouped;
    }, {});
}
export function formatDate(value, format) {
    if (!value) {
        return undefined;
    }
    return moment.unix(value).format(format);
}
export function arrayLast(array) {
    if (!array || (array.length == 0)) {
        return undefined;
    }
    return array[array.length - 1];
}
export function formatNumber(value, digits) {
    if (digits == undefined) {
        digits = 0;
    }
    if (isNaN(value)) {
        value = 0;
    }
    return Intl.NumberFormat('en-zw', { minimumFractionDigits: digits }).format(+(+value).toFixed(digits));
}
export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function max(array, map) {
    return Math.max.apply(Math, array.map(map));
}
