/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@clr/angular";
import * as i3 from "../../../../node_modules/firebaseui-angular/firebaseui-angular.ngfactory";
import * as i4 from "firebaseui-angular";
import * as i5 from "@angular/fire/auth";
import * as i6 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i7 from "@angular/common";
import * as i8 from "./login.component";
import * as i9 from "../../core/services/auth.service";
import * as i10 from "@angular/router";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "bg-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "header", [["class", "header-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "branding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "a", [["class", "nav-link"], ["href", "mailto:sales@gnarus.io"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "clr-icon", [["shape", "email"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["sales@gnarus.io"])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "table", [["class", "table-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "firebase-ui", [], null, [[null, "signInSuccessWithAuthResult"], [null, "signInFailure"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("signInSuccessWithAuthResult" === en)) {
        var pd_0 = (_co.successCallback($event) !== false);
        ad = (pd_0 && ad);
    } if (("signInFailure" === en)) {
        var pd_1 = (_co.errorCallback($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_FirebaseuiAngularLibraryComponent_0, i3.RenderType_FirebaseuiAngularLibraryComponent)), i1.ɵdid(13, 245760, null, 0, i4.FirebaseuiAngularLibraryComponent, [i5.AngularFireAuth, "firebaseUIAuthConfig", "firebaseUIAuthConfigFeature", i1.NgZone, i4.FirebaseuiAngularLibraryService], null, { signInSuccessWithAuthResultCallback: "signInSuccessWithAuthResult", signInFailureCallback: "signInFailure" }), (_l()(), i1.ɵeld(14, 0, null, null, 11, "clr-modal", [], [[2, "open", null]], [[null, "clrModalOpenChange"], ["body", "keyup.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("body:keyup.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).close() !== false);
        ad = (pd_0 && ad);
    } if (("clrModalOpenChange" === en)) {
        var pd_1 = (!!(_co.errorMessage = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ClrModal_0, i6.RenderType_ClrModal)), i1.ɵprd(8704, null, i2.ɵdl, i2.ɵdl, [i7.DOCUMENT]), i1.ɵprd(1024, null, i2.ɵdh, i2.ɵdi, []), i1.ɵdid(17, 704512, null, 0, i2.ClrModal, [i2.ɵdl, i2.ClrCommonStrings, i2.ɵdh], { _open: [0, "_open"] }, { _openChanged: "clrModalOpenChange" }), (_l()(), i1.ɵeld(18, 0, null, 1, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Failed to Sign In"])), (_l()(), i1.ɵeld(20, 0, null, 2, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, ["", ""])), (_l()(), i1.ɵeld(23, 0, null, 3, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.errorMessage = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok"])), (_l()(), i1.ɵeld(26, 0, null, null, 8, "clr-modal", [], [[2, "open", null]], [[null, "clrModalOpenChange"], ["body", "keyup.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("body:keyup.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 29).close() !== false);
        ad = (pd_0 && ad);
    } if (("clrModalOpenChange" === en)) {
        var pd_1 = (!!(_co.loadingMessage = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ClrModal_0, i6.RenderType_ClrModal)), i1.ɵprd(8704, null, i2.ɵdl, i2.ɵdl, [i7.DOCUMENT]), i1.ɵprd(1024, null, i2.ɵdh, i2.ɵdi, []), i1.ɵdid(29, 704512, null, 0, i2.ClrModal, [i2.ɵdl, i2.ClrCommonStrings, i2.ɵdh], { _open: [0, "_open"], closable: [1, "closable"], size: [2, "size"] }, { _openChanged: "clrModalOpenChange" }), (_l()(), i1.ɵeld(30, 0, null, 1, 1, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(31, null, [" ", " "])), (_l()(), i1.ɵeld(32, 0, null, 2, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 1, "div", [["class", "progress loop"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 0, "progress", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 13, 0); var currVal_1 = !!_co.errorMessage; _ck(_v, 17, 0, currVal_1); var currVal_4 = !!_co.loadingMessage; var currVal_5 = false; var currVal_6 = "sm"; _ck(_v, 29, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 17)._open; _ck(_v, 14, 0, currVal_0); var currVal_2 = _co.errorMessage; _ck(_v, 22, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 29)._open; _ck(_v, 26, 0, currVal_3); var currVal_7 = _co.loadingMessage; _ck(_v, 31, 0, currVal_7); }); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 245760, null, 0, i8.LoginComponent, [i9.AuthService, i10.Router, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i8.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
