var ClaimsUtil = /** @class */ (function () {
    function ClaimsUtil() {
    }
    ClaimsUtil.hasAnyPermission = function (claims, deviceId, permissions) {
        // User has claims to no devices, return false
        if (!claims || !claims.devices || !claims[deviceId]) {
            return false;
        }
        // If no permissions given, just check if there are any claims on the device at all
        if (!permissions || permissions.length == 0) {
            // We have already checked above whether there are claims for this device, so return true
            return true;
        }
        for (var _i = 0, permissions_1 = permissions; _i < permissions_1.length; _i++) {
            var permission = permissions_1[_i];
            if (claims.devices[deviceId].includes(permission)) {
                return true;
            }
        }
        return false;
    };
    ClaimsUtil.deviceIds = function (claims) {
        if (!claims || !claims.devices) {
            return [];
        }
        return Object.keys(claims.devices);
    };
    ClaimsUtil.permissions = function (claims) {
        var deviceIds = ClaimsUtil.deviceIds(claims);
        var permissions = {};
        for (var _i = 0, deviceIds_1 = deviceIds; _i < deviceIds_1.length; _i++) {
            var deviceId = deviceIds_1[_i];
            permissions[deviceId] = ClaimsUtil.forDevice(claims, deviceId);
        }
        return permissions;
    };
    ClaimsUtil.forDevice = function (claims, deviceId) {
        if (!claims.devices[deviceId]) {
            return {};
        }
        var claimsList = claims.devices[deviceId].split('|');
        var claimsObject = {};
        for (var _i = 0, claimsList_1 = claimsList; _i < claimsList_1.length; _i++) {
            var claim = claimsList_1[_i];
            claimsObject[claim] = true;
        }
        return claimsObject;
    };
    return ClaimsUtil;
}());
export default ClaimsUtil;
