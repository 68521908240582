import {Component, OnInit} from '@angular/core';
import {Angulartics2GoogleGlobalSiteTag} from 'angulartics2/gst';
import {ApmService} from "./core/services/apm.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    private angulartics: Angulartics2GoogleGlobalSiteTag,
    private apmService: ApmService
  ) {
    angulartics.startTracking();
    apmService.init();
  }

  ngOnInit(): void {
  }
}
