import { Component, OnInit } from '@angular/core';
import {FieldType} from "@ngx-formly/core";

@Component({
  selector: 'app-clarity-select',
  templateUrl: './clarity-select.component.html',
  styleUrls: ['./clarity-select.component.scss']
})
export class ClaritySelectComponent extends FieldType {
}
