import {NgModule} from '@angular/core';
import {SharedModule} from "../shared/shared.module";
import {LoginComponent} from "./login/login.component";
import {PublicRoutes} from "./public.routes";
import {FirebaseUIModule} from "firebaseui-angular";

@NgModule({
  imports: [
    PublicRoutes,
    FirebaseUIModule.forFeature({}),
    SharedModule.forChild()
  ],
  declarations: [
    LoginComponent
  ]
})
export class PublicModule {
}
