import {unsubscribe} from "../../../../hosting/src/app/shared/util/rxjs.util";
import {Observable, Subscription} from "rxjs";

export function asPromise<T>(observable: Observable<T>): Promise<T> {
  let resolver = (subsription: Subscription, resolve: Function, result: T) => {
    resolve(result);
    unsubscribe(subsription);
  };
  let rejecter = (subsription: Subscription, reject: Function, error: any) => {
    reject(error);
    unsubscribe(subsription);
  };

  return new Promise((resolve, reject) => {
    let subscription = observable.subscribe(
      result => {
        resolver(subscription, resolve, result);
      },
      error => {
        rejecter(subscription, reject, error);
      }
    );
  });
}
