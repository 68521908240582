<div class="bg-wrapper">

  <header class="header-6">
    <div class="branding">
      <a href="mailto:sales@gnarus.io" class="nav-link">
        <clr-icon shape="email"></clr-icon>
        <span class="title">sales@gnarus.io</span>
      </a>
    </div>
  </header>
  <table class="table-wrapper">
    <tr>
      <td>
        <firebase-ui
          (signInSuccessWithAuthResult)="successCallback($event)"
          (signInFailure)="errorCallback($event)"></firebase-ui>
      </td>
    </tr>
  </table>
</div>

<clr-modal [(clrModalOpen)]="!!errorMessage">
  <h3 class="modal-title">Failed to Sign In</h3>
  <div class="modal-body">
    <p>{{errorMessage}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="errorMessage = null">Ok</button>
  </div>
</clr-modal>

<clr-modal [clrModalSize]="'sm'" [(clrModalOpen)]="!!loadingMessage" [clrModalClosable]="false">
  <h5 class="modal-title">
    {{loadingMessage}}
  </h5>
  <div class="modal-body">
    <div class="progress loop">
      <progress></progress>
    </div>
  </div>
</clr-modal>
