var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Dexie from "dexie";
import * as i0 from "@angular/core";
var DexieService = /** @class */ (function (_super) {
    __extends(DexieService, _super);
    function DexieService() {
        var _this = _super.call(this, 'gnarus') || this;
        var dexie = _this;
        dexie.version(1).stores({
            records: 'id, timestamp, deleteAfter',
        });
        dexie.version(2).upgrade(function (tx) {
            return tx.table('records').clear();
        });
        dexie.version(3).stores({
            records: null,
        });
        dexie.version(4)
            .stores({
            devices: '$id',
            public: '$id',
            records: '$id, timestamp, deleteAfter',
            users: '$id'
        });
        dexie.version(5)
            .stores({
            admin: '$id',
            devices: '$id',
            public: '$id',
            records: '$id, timestamp, deleteAfter',
            users: '$id'
        });
        dexie.version(6)
            .stores({
            admin: '$id',
            applicationSettings: '$id',
            devices: '$id',
            public: '$id',
            records: '$id, timestamp, deleteAfter',
            users: '$id'
        });
        dexie.version(7)
            .stores({
            admin: '$id',
            applicationSettings: '$id',
            devices: '$id',
            formSubmissions: '$id, created',
            public: '$id',
            records: '$id, timestamp, deleteAfter',
            users: '$id'
        });
        dexie.version(8)
            .stores({
            admin: '$id',
            applicationSettings: '$id',
            devices: '$id',
            formSubmissions: '$id, created, updated',
            public: '$id',
            records: '$id, timestamp, deleteAfter',
            users: '$id'
        });
        dexie.version(9)
            .stores({
            admin: '$id',
            alertStates: '$id',
            applicationSettings: '$id',
            devices: '$id',
            formSubmissions: '$id, created, updated',
            public: '$id',
            records: '$id, timestamp, deleteAfter',
            users: '$id'
        });
        dexie.version(10)
            .stores({
            admin: '$id',
            alertStates: '$id',
            applicationSettings: '$id',
            devices: '$id',
            fanSpeeds: '$id',
            formSubmissions: '$id, created, updated',
            public: '$id',
            records: '$id, timestamp, deleteAfter',
            users: '$id'
        });
        dexie.version(11)
            .stores({
            admin: '$id',
            alertStates: '$id',
            applicationSettings: '$id',
            barcodes: '$id, number',
            devices: '$id',
            fanSpeeds: '$id',
            formSubmissions: '$id, created, updated',
            public: '$id',
            records: '$id, timestamp, deleteAfter',
            users: '$id'
        });
        dexie.version(12)
            .stores({
            admin: '$id',
            alertStates: '$id',
            applicationSettings: '$id',
            barcodes: '$id, number, deviceId',
            devices: '$id',
            fanSpeeds: '$id',
            formSubmissions: '$id, created, updated',
            public: '$id',
            records: '$id, timestamp, deleteAfter',
            users: '$id'
        });
        dexie.version(13)
            .stores({
            admin: '$id',
            alertStates: '$id',
            applicationSettings: '$id',
            barcodes: '$id, number, deviceId, [deviceId+number]',
            devices: '$id',
            fanSpeeds: '$id',
            formSubmissions: '$id, created, updated',
            public: '$id',
            records: '$id, timestamp, deleteAfter',
            users: '$id'
        });
        dexie.version(14)
            .stores({
            admin: '$id',
            alertStates: '$id, updated',
            applicationSettings: '$id',
            barcodes: '$id, number, deviceId, [deviceId+number], updated',
            devices: '$id',
            fanSpeeds: '$id, updated',
            formSubmissions: '$id, created, updated',
            public: '$id',
            records: '$id, timestamp, updated',
            users: '$id'
        });
        dexie.version(15)
            .stores({
            admin: '$id',
            alertStates: '$id, updated, $pendingUpload',
            applicationSettings: '$id',
            barcodes: '$id, number, deviceId, [deviceId+number], updated, $pendingUpload',
            devices: '$id',
            fanSpeeds: '$id, updated, $pendingUpload',
            formSubmissions: '$id, created, updated, $pendingUpload',
            public: '$id',
            records: '$id, timestamp, updated, $pendingUpload',
            users: '$id'
        });
        dexie.version(16)
            .stores({
            admin: '$id',
            alertStates: '$id, updated, $pendingUpload, $synced',
            applicationSettings: '$id',
            barcodes: '$id, number, deviceId, [deviceId+number], updated, $pendingUpload, $synced',
            devices: '$id',
            fanSpeeds: '$id, updated, $pendingUpload, $synced',
            formSubmissions: '$id, created, updated, $pendingUpload, $synced',
            public: '$id',
            records: '$id, timestamp, updated, $pendingUpload, $synced',
            users: '$id'
        });
        dexie.version(17)
            .stores({
            admin: '$id',
            alertStates: '$id, updated, $pendingUpload, $synced',
            applicationSettings: '$id',
            barcodes: '$id, number, deviceId, [deviceId+number], updated, $pendingUpload, $synced',
            devices: '$id',
            fanSpeeds: '$id, updated, $pendingUpload, $synced',
            formSubmissions: '$id, created, updated, $pendingUpload, $synced',
            meta: '$id',
            public: '$id',
            records: '$id, timestamp, updated, $pendingUpload, $synced',
            users: '$id'
        });
        dexie.version(18)
            .stores({
            admin: '$id',
            alertStates: '$id, updated, deviceId, $pendingUpload',
            applicationSettings: '$id',
            barcodes: '$id, created, updated, deviceId, $pendingUpload, [deviceId+number]',
            devices: '$id, updated',
            fanSpeeds: '$id, updated, $pendingUpload',
            formSubmissions: '$id, created, updated, deviceId, $pendingUpload, [deviceId+formId], [deviceId+number]',
            meta: '$id',
            public: '$id',
            records: '$id, timestamp, updated, $pendingUpload',
            users: '$id'
        });
        dexie.version(19)
            .stores({
            admin: '$id',
            alertStates: '$id, updated, deviceId, $pendingUpload',
            applicationSettings: '$id',
            barcodes: '$id, created, updated, deviceId, $pendingUpload, [deviceId+number]',
            devices: '$id, updated',
            fanSpeeds: '$id, updated, $pendingUpload',
            formSubmissions: '$id, created, updated, deviceId, $pendingUpload, [deviceId+formId], [deviceId+number]',
            meta: '$id',
            public: '$id',
            records: '$id, timestamp, updated, $pendingUpload, $deviceId',
            users: '$id'
        });
        dexie.version(20)
            .stores({
            admin: '$id',
            alertStates: '$id, updated, $deviceId, deviceId, $pendingUpload',
            applicationSettings: '$id',
            barcodes: '$id, created, updated, deviceId, $deviceId, $pendingUpload, [deviceId+number]',
            devices: '$id, updated, $pendingUpload',
            fanSpeeds: '$id, updated, $pendingUpload',
            formSubmissions: '$id, created, updated, deviceId, $deviceId, $pendingUpload, [deviceId+formId], [deviceId+number]',
            meta: '$id',
            public: '$id',
            records: '$id, timestamp, updated, $deviceId, $pendingUpload',
            users: '$id'
        });
        dexie.version(21)
            .stores({
            admin: '$id',
            alertStates: '$id, updated, $deviceId, deviceId, $pendingUpload',
            applicationSettings: '$id',
            barcodes: '$id, created, updated, deviceId, $deviceId, $pendingUpload, [deviceId+number]',
            devices: '$id, updated, $pendingUpload',
            fanSpeeds: '$id, updated, $pendingUpload, $deviceId',
            formSubmissions: '$id, created, updated, deviceId, $deviceId, $pendingUpload, [deviceId+formId], [deviceId+number]',
            meta: '$id',
            public: '$id',
            records: '$id, timestamp, updated, $deviceId, $pendingUpload',
            users: '$id'
        });
        dexie.version(22)
            .stores({
            admin: '$id',
            alertStates: '$id, updated, $deviceId, deviceId, $pendingUpload',
            applicationSettings: '$id',
            barcodes: '$id, created, updated, deviceId, $deviceId, $pendingUpload, [deviceId+number]',
            devices: '$id, updated, $pendingUpload',
            fanSpeeds: '$id, updated, $pendingUpload, $deviceId',
            formSubmissions: '$id, created, updated, deviceId, $deviceId, $pendingUpload, [deviceId+formId], [deviceId+number]',
            meta: '$id',
            public: '$id',
            records: '$id, timestamp, updated, deviceId, $deviceId, $pendingUpload',
            users: '$id'
        });
        dexie.version(23)
            .stores({
            admin: '$id',
            alertStates: '$id, updated, $deviceId, deviceId, $pendingUpload',
            applicationSettings: '$id',
            barcodes: '$id, created, updated, deviceId, $deviceId, $pendingUpload, [deviceId+number]',
            devices: '$id, updated, $pendingUpload',
            fanSpeeds: '$id, updated, $pendingUpload, $deviceId, deviceId',
            formSubmissions: '$id, created, updated, deviceId, $deviceId, $pendingUpload, [deviceId+formId], [deviceId+number]',
            meta: '$id',
            public: '$id',
            records: '$id, timestamp, updated, deviceId, $deviceId, $pendingUpload',
            users: '$id'
        });
        dexie.version(24)
            .stores({
            admin: '$id',
            alertStates: '$id, updated, $deviceId, deviceId, $pendingUpload',
            applicationSettings: '$id',
            barcodes: '$id, created, updated, deviceId, $deviceId, $pendingUpload, [deviceId+number], imported',
            devices: '$id, updated, $pendingUpload',
            fanSpeeds: '$id, updated, $pendingUpload, $deviceId, deviceId',
            formSubmissions: '$id, created, updated, deviceId, $deviceId, $pendingUpload, [deviceId+formId], [deviceId+number], imported',
            meta: '$id',
            public: '$id',
            records: '$id, timestamp, updated, deviceId, $deviceId, $pendingUpload',
            users: '$id'
        });
        return _this;
    }
    DexieService.ngInjectableDef = i0.defineInjectable({ factory: function DexieService_Factory() { return new DexieService(); }, token: DexieService, providedIn: "root" });
    return DexieService;
}(Dexie));
export { DexieService };
