var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { LocalDbService } from "../services/database/local-db.service";
import AbstractRepository from "./abstract.repository";
import * as i0 from "@angular/core";
import * as i1 from "../services/database/local-db.service";
var UserRepository = /** @class */ (function (_super) {
    __extends(UserRepository, _super);
    function UserRepository(localdb) {
        return _super.call(this, localdb, 'users') || this;
    }
    UserRepository.ngInjectableDef = i0.defineInjectable({ factory: function UserRepository_Factory() { return new UserRepository(i0.inject(i1.LocalDbService)); }, token: UserRepository, providedIn: "root" });
    return UserRepository;
}(AbstractRepository));
export { UserRepository };
