import {Injectable} from '@angular/core';
import AbstractRepository from "./abstract.repository";
import ApplicationSetting from "../models/application-setting";
import {LocalDbService} from "../services/database/local-db.service";

@Injectable({
  providedIn: 'root'
})
export class ApplicationSettingRepository extends AbstractRepository<ApplicationSetting> {

  constructor(localdb: LocalDbService) {
    super(localdb, 'applicationSettings')
  }


}
