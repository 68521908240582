var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject } from "rxjs";
import { Mutex } from "async-mutex";
import { RecordRepository } from "../repositories/record.repository";
import { listToObject } from "../../shared/util/util";
import { DeviceRepository } from "../repositories/device.repository";
import { LocalDbService } from "./database/local-db.service";
import { FormSubmissionRepository } from "../repositories/form-submission.repository";
import * as i0 from "@angular/core";
import * as i1 from "../repositories/record.repository";
import * as i2 from "../repositories/device.repository";
import * as i3 from "../repositories/form-submission.repository";
import * as i4 from "./database/local-db.service";
var CacheService = /** @class */ (function () {
    function CacheService(recordRepository, deviceRepository, formSubmissionRepository, localdb) {
        this.recordRepository = recordRepository;
        this.deviceRepository = deviceRepository;
        this.formSubmissionRepository = formSubmissionRepository;
        this.localdb = localdb;
        this.cache = {};
        this.mutex = new Mutex();
        this._registering$ = new BehaviorSubject(null);
    }
    Object.defineProperty(CacheService.prototype, "registering$", {
        get: function () {
            return this._registering$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CacheService.prototype.get = function (tag, getTimestamp, fetch) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, release, models;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.mutex.acquire()];
                    case 1:
                        release = _b.sent();
                        return [4 /*yield*/, fetch(this.cache[tag].latest)];
                    case 2:
                        models = _b.sent();
                        if (!!models && models.length > 0) {
                            (_a = this.cache[tag].models).push.apply(_a, models);
                            this.cache[tag].latest = getTimestamp(models[models.length - 1]);
                        }
                        release();
                        return [2 /*return*/, this.cache[tag].models];
                }
            });
        });
    };
    CacheService.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.initRecords()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.initFormSubmissions()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CacheService.prototype.register = function (tag, getTimestamp, fetch) {
        return __awaiter(this, void 0, void 0, function () {
            var models, latest;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log(tag);
                        this._registering$.next(this._registering$.value + 1);
                        return [4 /*yield*/, fetch()];
                    case 1:
                        models = _a.sent();
                        latest = 0;
                        if (models.length > 0) {
                            latest = getTimestamp(models[models.length - 1]);
                        }
                        this.cache[tag] = {
                            latest: latest,
                            models: models
                        };
                        this._registering$.next(this._registering$.value - 1);
                        return [2 /*return*/];
                }
            });
        });
    };
    CacheService.prototype.initFormSubmissions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.register('formSubmissions', function (model) { return model.updated; }, function () {
                            return _this.formSubmissionRepository.list(null, 'updated');
                        })];
                    case 1:
                        _a.sent();
                        this.formSubmissionRepository.cachedList = function (tag) { return __awaiter(_this, void 0, void 0, function () {
                            var _a;
                            var _this = this;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _a = tag;
                                        switch (_a) {
                                            case 'formSubmissions': return [3 /*break*/, 1];
                                        }
                                        return [3 /*break*/, 3];
                                    case 1: return [4 /*yield*/, this.get('formSubmissions', function (model) { return model.updated; }, function (latest) {
                                            return _this.localdb.between("formSubmissions", "updated", latest);
                                        })];
                                    case 2: return [2 /*return*/, _b.sent()];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); };
                        return [2 /*return*/];
                }
            });
        });
    };
    CacheService.prototype.initRecords = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.register('records', function (model) { return model.timestamp; }, function () {
                            return _this.recordRepository.list(null, 'timestamp').then(function (models) { return __awaiter(_this, void 0, void 0, function () {
                                var devices, _a, _i, models_1, record;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = listToObject;
                                            return [4 /*yield*/, this.deviceRepository.list()];
                                        case 1:
                                            devices = _a.apply(void 0, [_b.sent(), '$id']);
                                            for (_i = 0, models_1 = models; _i < models_1.length; _i++) {
                                                record = models_1[_i];
                                                if (!!record.values && !record.values['C1']) {
                                                    RecordRepository.calculateRecord(record, devices[record.deviceId]);
                                                }
                                            }
                                            return [2 /*return*/, models];
                                    }
                                });
                            }); });
                        })];
                    case 1:
                        _a.sent();
                        this.recordRepository.cachedList = function (tag) { return __awaiter(_this, void 0, void 0, function () {
                            var _a;
                            var _this = this;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _a = tag;
                                        switch (_a) {
                                            case 'records': return [3 /*break*/, 1];
                                        }
                                        return [3 /*break*/, 3];
                                    case 1: return [4 /*yield*/, this.get('records', function (model) { return model.timestamp; }, function (latest) {
                                            return _this.localdb.between("records", "timestamp", latest)
                                                .then(function (records) { return __awaiter(_this, void 0, void 0, function () {
                                                var devices, _a, _i, records_1, record;
                                                return __generator(this, function (_b) {
                                                    switch (_b.label) {
                                                        case 0:
                                                            _a = listToObject;
                                                            return [4 /*yield*/, this.deviceRepository.list()];
                                                        case 1:
                                                            devices = _a.apply(void 0, [_b.sent(), '$id']);
                                                            for (_i = 0, records_1 = records; _i < records_1.length; _i++) {
                                                                record = records_1[_i];
                                                                if (!!record.values && !record.values['C1']) {
                                                                    RecordRepository.calculateRecord(record, devices[record.deviceId]);
                                                                }
                                                            }
                                                            return [2 /*return*/, records];
                                                    }
                                                });
                                            }); });
                                        })];
                                    case 2: return [2 /*return*/, _b.sent()];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); };
                        return [2 /*return*/];
                }
            });
        });
    };
    CacheService.ngInjectableDef = i0.defineInjectable({ factory: function CacheService_Factory() { return new CacheService(i0.inject(i1.RecordRepository), i0.inject(i2.DeviceRepository), i0.inject(i3.FormSubmissionRepository), i0.inject(i4.LocalDbService)); }, token: CacheService, providedIn: "root" });
    return CacheService;
}());
export { CacheService };
