var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { environment } from "../../../environments/environment";
import { init as initApm } from 'elastic-apm-js-base';
import { DeviceDetectorService } from "ngx-device-detector";
import { Subject } from "rxjs";
import { UnsubscribeOnDestroy } from "../../protected/unsubscribe-on-destroy";
import * as i0 from "@angular/core";
import * as i1 from "ngx-device-detector";
var ApmService = /** @class */ (function (_super) {
    __extends(ApmService, _super);
    function ApmService(deviceService) {
        var _this = _super.call(this) || this;
        _this.deviceService = deviceService;
        _this.console$ = new Subject();
        _this.tags = {};
        return _this;
    }
    ApmService.prototype.error = function (e) {
        console.error(e);
        this.apm.captureError(e);
    };
    ApmService.prototype.init = function () {
        this.apm = initApm({
            // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
            serviceName: 'gnarus-app',
            // Set custom APM Server URL (default: http://localhost:8200)
            serverUrl: 'https://5e8d2ed513b7486b9fcef6eb7a27efdc.apm.us-east-1.aws.cloud.es.io:443',
            serviceVersion: environment.version
        });
        this.tags.appVersion = environment.version;
        this.tags.environment = environment.production ? 'production' : 'development';
        this.tags.environment = environment.production ? 'production' : 'development';
        this.tags = __assign({}, this.tags, this.deviceService.getDeviceInfo());
        if (this.deviceService.isDesktop()) {
            this.tags.deviceType = 'desktop';
        }
        if (this.deviceService.isMobile()) {
            this.tags.deviceType = 'mobile';
        }
        if (this.deviceService.isTablet()) {
            this.tags.deviceType = 'tablet';
        }
    };
    ApmService.prototype.metric = function (metric) {
        gtag('event', metric.action, {
            'event_category': 'metric',
            'event_label': metric.label,
            'value': metric.value
        });
        var tags = metric.tags;
        if (!!metric.device) {
            if (!tags) {
                tags = {};
            }
            tags.deviceId = metric.device.$id;
            tags.deviceName = metric.device.settings.name;
        }
        if (!tags) {
            tags = {};
        }
        tags.value = metric.value;
        var transaction = this.apm.startTransaction(metric.label, metric.action);
        var span = transaction.startSpan(metric.label, metric.action);
        span.addTags(__assign({}, this.tags, tags));
        span.end();
        transaction.end();
        var newMetric = __assign({}, metric);
        delete newMetric.device;
        delete newMetric.tags;
        console.debug("(APM) Send metric: " + JSON.stringify(newMetric));
    };
    ApmService.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    ApmService.prototype.putContext = function (key, value) {
        var tag = {};
        tag[key] = value;
        this.tags = __assign({}, this.tags, tag);
        this.apm.setCustomContext(this.tags);
        console.debug("(APM) Set context: " + JSON.stringify(tag));
    };
    ApmService.prototype.setUser = function (uid, fullName, email) {
        console.debug("(APM) Set user: " + uid + ", " + fullName + ", " + email);
        gtag('set', { 'user_id': uid });
        ga('set', 'userId', uid);
        this.tags = __assign({}, this.tags, {
            uid: uid,
            fullName: fullName
        });
        this.apm.setUserContext({
            id: uid,
            username: fullName,
            email: email
        });
    };
    ApmService.ngInjectableDef = i0.defineInjectable({ factory: function ApmService_Factory() { return new ApmService(i0.inject(i1.DeviceDetectorService)); }, token: ApmService, providedIn: "root" });
    return ApmService;
}(UnsubscribeOnDestroy));
export { ApmService };
