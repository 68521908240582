import {Injectable} from '@angular/core';
import {SwUpdate} from "@angular/service-worker";
import {BehaviorSubject} from "rxjs";
import {UnsubscribeOnDestroy} from "../unsubscribe-on-destroy";
import {ApmService} from "../../core/services/apm.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService extends UnsubscribeOnDestroy {

  private updateMessage = new BehaviorSubject<string>(undefined);

  constructor(
    private swUpdate: SwUpdate,
    private apmService: ApmService
  ) {
    super();

    if (swUpdate.isEnabled) {
      this.subs.push(swUpdate.available.subscribe(event => {
        this.updateMessage.next(`App Updated`)
      }))
    }

  }

  get updateMessage$() {
    return this.updateMessage.asObservable();
  }

  static onMessage(type: string, on: (data?: any) => void) {

    navigator.serviceWorker.addEventListener('message', function handler(event) {
      if (event.data.type === type) {
        if (!!event.data.data) {
          on(JSON.parse(event.data.data));
        } else {
          on()
        }
      }
    });
  }

  static sendMessage(type: string, data?: any) {
    if (!!navigator.serviceWorker) {
      if (!!data) {
        navigator.serviceWorker.controller.postMessage({type: type, data: JSON.stringify(data)});
      } else {
        navigator.serviceWorker.controller.postMessage({type: type});
      }
    }
  }

  static async serviceWorker(): Promise<ServiceWorkerRegistration> {

    if (!environment.production) {
      await navigator.serviceWorker.register('/sw-sync.js');
    } else {
      await navigator.serviceWorker.register('/sw-master.js');
    }
    return navigator.serviceWorker.ready;
  }

  static unRegister() {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    })
  }

  async init() {

    await ServiceWorkerService.serviceWorker();

    ServiceWorkerService.onMessage('error', error => {
      this.onError(error);
    });

    ServiceWorkerService.onMessage('version', version => {
      this.apmService.putContext('serviceWorkerVersion', version);
    });

    ServiceWorkerService.sendMessage('version');
  }

  async update() {
    await this.swUpdate.activateUpdate();
    this.updateMessage.next(undefined);
    window.location.reload();
  }

  private onError(error) {
    console.error(error);
    this.apmService.error(error)
  }
}
