<clr-checkbox-container>
  <!--<label  *ngIf="to.label">{{to.label}}</label>-->
  <clr-checkbox-wrapper *ngFor="let option of to.options; let i = index;">
    <input type="checkbox" clrCheckbox
           [id]="id + '_' + i"
           [formlyAttributes]="field"
           [checked]="formControl.value && (formControl.value.indexOf(option.value) >= 0)"
           (change)="onChange(option.value, $event.target.checked)"/>
    <label>{{option.name}}</label>
  </clr-checkbox-wrapper>
  <clr-control-helper *ngIf="to.placeholder">{{to.placeholder}}</clr-control-helper>
</clr-checkbox-container>
