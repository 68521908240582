var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LocalDbService } from "../services/database/local-db.service";
import AbstractRepository from "./abstract.repository";
import * as i0 from "@angular/core";
import * as i1 from "../services/database/local-db.service";
var RecordRepository = /** @class */ (function (_super) {
    __extends(RecordRepository, _super);
    function RecordRepository(localdb) {
        return _super.call(this, localdb, "records") || this;
    }
    RecordRepository.calculate = function (record, calculation) {
        switch (calculation.type) {
            case 'WET_BULB': {
                if (!!record.values) {
                    var dryBulb = record.values[calculation.inputs[0]];
                    var rh = record.values[calculation.inputs[1]];
                    return dryBulb * Math.atan(0.151977 * Math.sqrt(rh + 8.313659)) + Math.atan(dryBulb + rh)
                        - Math.atan(rh - 1.676331) + 0.00391838 * Math.pow(Math.sqrt(rh), 3) * Math.atan(0.023101 * rh)
                        - 4.686035;
                }
            }
        }
        return 0;
    };
    RecordRepository.calculateRecord = function (record, device) {
        for (var _i = 0, _a = Object.keys(record.values); _i < _a.length; _i++) {
            var sensorId = _a[_i];
            var value = record.values[sensorId];
            if (value < 0) {
                record.values[sensorId] = 0;
            }
        }
        for (var calcId in device.calculations) {
            record.values[calcId] = RecordRepository.calculate(record, device.calculations[calcId]);
        }
    };
    RecordRepository.prototype.byDeviceId = function (deviceId) {
        return __awaiter(this, void 0, void 0, function () {
            var records;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cachedList('records')];
                    case 1:
                        records = _a.sent();
                        return [2 /*return*/, records.filter(function (a) { return a.deviceId === deviceId; })];
                }
            });
        });
    };
    RecordRepository.prototype.lastByDeviceId = function (deviceId) {
        return __awaiter(this, void 0, void 0, function () {
            var records;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.byDeviceId(deviceId)];
                    case 1:
                        records = _a.sent();
                        if (!!records && records.length > 0) {
                            return [2 /*return*/, records[records.length - 1]];
                        }
                        return [2 /*return*/, null];
                }
            });
        });
    };
    RecordRepository.prototype.sliceByDeviceId = function (deviceId, start, end) {
        return __awaiter(this, void 0, void 0, function () {
            var records;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.byDeviceId(deviceId)];
                    case 1:
                        records = _a.sent();
                        return [2 /*return*/, records.filter(function (record) { return (record.timestamp >= start) && (!end || (record.timestamp < end)); })];
                }
            });
        });
    };
    RecordRepository.ngInjectableDef = i0.defineInjectable({ factory: function RecordRepository_Factory() { return new RecordRepository(i0.inject(i1.LocalDbService)); }, token: RecordRepository, providedIn: "root" });
    return RecordRepository;
}(AbstractRepository));
export { RecordRepository };
