var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LocalDbService } from "../services/database/local-db.service";
import AbstractRepository from "./abstract.repository";
import { AuthService } from "../../core/services/auth.service";
import ClaimsUtil from "../../shared/util/claims.util";
import { SyncService } from "../services/sync.service";
import * as i0 from "@angular/core";
import * as i1 from "../services/database/local-db.service";
import * as i2 from "../services/sync.service";
import * as i3 from "../../core/services/auth.service";
var DeviceRepository = /** @class */ (function (_super) {
    __extends(DeviceRepository, _super);
    function DeviceRepository(localdb, syncService, authService) {
        var _this = _super.call(this, localdb, "devices", syncService, { shouldUpload: true }) || this;
        _this.authService = authService;
        return _this;
    }
    DeviceRepository.prototype.list = function () {
        return __awaiter(this, void 0, void 0, function () {
            var user, deviceIds, list;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authService.user()];
                    case 1:
                        user = _a.sent();
                        deviceIds = ClaimsUtil.deviceIds(user.claims);
                        return [4 /*yield*/, _super.prototype.list.call(this)];
                    case 2:
                        list = _a.sent();
                        return [2 /*return*/, list.filter(function (d) { return deviceIds.some(function (id) { return id === d.$id; }); })];
                }
            });
        });
    };
    DeviceRepository.prototype.enrichFormFields = function (forms) {
        for (var _i = 0, _a = Object.values(forms); _i < _a.length; _i++) {
            var form = _a[_i];
            var _loop_1 = function (field) {
                switch (field.type) {
                    case "select":
                        field.templateOptions.options.forEach(function (option, i) {
                            if (typeof option === "string") {
                                field.templateOptions.options[i] = {
                                    label: option,
                                    value: option
                                };
                            }
                            if (typeof option === "number") {
                                field.templateOptions.options[i] = {
                                    label: "" + option,
                                    value: option
                                };
                            }
                        });
                        if (field.templateOptions.sort) {
                            field.templateOptions.options = field.templateOptions.options.sort(function (a, b) { return a.label.localeCompare(b.label); });
                        }
                }
            };
            for (var _b = 0, _c = form.fields; _b < _c.length; _b++) {
                var field = _c[_b];
                _loop_1(field);
            }
        }
    };
    DeviceRepository.prototype.setDeviceId = function (items, deviceId) {
        Object.values(items).forEach(function (i) { return i.deviceId = deviceId; });
    };
    DeviceRepository.ngInjectableDef = i0.defineInjectable({ factory: function DeviceRepository_Factory() { return new DeviceRepository(i0.inject(i1.LocalDbService), i0.inject(i2.SyncService), i0.inject(i3.AuthService)); }, token: DeviceRepository, providedIn: "root" });
    return DeviceRepository;
}(AbstractRepository));
export { DeviceRepository };
