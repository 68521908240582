/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clarity-multicheckbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i3 from "@clr/angular";
import * as i4 from "@ngx-formly/core";
import * as i5 from "@angular/common";
import * as i6 from "./clarity-multicheckbox.component";
var styles_ClarityMulticheckboxComponent = [i0.styles];
var RenderType_ClarityMulticheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClarityMulticheckboxComponent, data: {} });
export { RenderType_ClarityMulticheckboxComponent as RenderType_ClarityMulticheckboxComponent };
function View_ClarityMulticheckboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "clr-checkbox-wrapper", [], [[2, "clr-checkbox-wrapper", null]], null, null, i2.View_ClrCheckboxWrapper_0, i2.RenderType_ClrCheckboxWrapper)), i1.ɵdid(1, 49152, null, 1, i3.ClrCheckboxWrapper, [], null, null), i1.ɵqud(335544320, 2, { label: 0 }), i1.ɵprd(512, null, i3.ɵq, i3.ɵq, []), (_l()(), i1.ɵeld(4, 16777216, null, 0, 2, "input", [["clrCheckbox", ""], ["type", "checkbox"]], [[8, "checked", 0], [8, "id", 0]], [[null, "change"], [null, "blur"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).triggerValidation() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onFocus() !== false);
        ad = (pd_1 && ad);
    } if (("blur" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).onBlur() !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = (_co.onChange(_v.context.$implicit.value, $event.target.checked) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 212992, null, 0, i3.ClrCheckbox, [i1.ViewContainerRef, i1.Injector, [8, null], i1.Renderer2, i1.ElementRef], { id: [0, "id"] }, null), i1.ɵdid(6, 540672, null, 0, i4.FormlyAttributes, [i1.Renderer2, i1.ElementRef], { field: [0, "field"] }, null), (_l()(), i1.ɵeld(7, 0, null, 1, 2, "label", [], [[1, "for", 0]], null, null, null, null)), i1.ɵdid(8, 212992, [[2, 4], [1, 4]], 0, i3.ClrLabel, [[2, i3.ɵq], [2, i3.ɵr], [2, i3.ɵs], i1.Renderer2, i1.ElementRef], null, null), (_l()(), i1.ɵted(9, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = ((_co.id + "_") + _v.context.index); _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.field; _ck(_v, 6, 0, currVal_4); _ck(_v, 8, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.formControl.value && (_co.formControl.value.indexOf(_v.context.$implicit.value) >= 0)); var currVal_2 = i1.ɵnov(_v, 5).id; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_5 = i1.ɵnov(_v, 8).forAttr; _ck(_v, 7, 0, currVal_5); var currVal_6 = _v.context.$implicit.name; _ck(_v, 9, 0, currVal_6); }); }
function View_ClarityMulticheckboxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "clr-control-helper", [], [[2, "clr-subtext", null]], null, null, i2.View_ClrControlHelper_0, i2.RenderType_ClrControlHelper)), i1.ɵdid(1, 49152, null, 0, i3.ClrControlHelper, [], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.to.placeholder; _ck(_v, 2, 0, currVal_1); }); }
export function View_ClarityMulticheckboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "clr-checkbox-container", [], [[2, "clr-form-control", null], [2, "clr-form-control-disabled", null], [2, "clr-row", null]], null, null, i2.View_ClrCheckboxContainer_0, i2.RenderType_ClrCheckboxContainer)), i1.ɵprd(512, null, i3.ɵs, i3.ɵs, []), i1.ɵprd(131584, null, i3.ɵt, i3.ɵt, [i3.ɵs]), i1.ɵprd(512, null, i3.ɵba, i3.ɵba, []), i1.ɵdid(4, 245760, null, 1, i3.ClrCheckboxContainer, [i3.ɵt, [2, i3.ɵr], i3.ɵba, i3.ɵs], null, null), i1.ɵqud(603979776, 1, { label: 0 }), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_ClarityMulticheckboxComponent_1)), i1.ɵdid(7, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, 2, 1, null, View_ClarityMulticheckboxComponent_2)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_3 = _co.to.options; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.to.placeholder; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = ((i1.ɵnov(_v, 4).control == null) ? null : i1.ɵnov(_v, 4).control.disabled); var currVal_2 = i1.ɵnov(_v, 4).addGrid(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ClarityMulticheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-clarity-multicheckbox", [], null, null, null, View_ClarityMulticheckboxComponent_0, RenderType_ClarityMulticheckboxComponent)), i1.ɵdid(1, 16760832, null, 0, i6.ClarityMulticheckboxComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClarityMulticheckboxComponentNgFactory = i1.ɵccf("app-clarity-multicheckbox", i6.ClarityMulticheckboxComponent, View_ClarityMulticheckboxComponent_Host_0, { form: "form", field: "field", model: "model", options: "options" }, {}, []);
export { ClarityMulticheckboxComponentNgFactory as ClarityMulticheckboxComponentNgFactory };
