var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SwUpdate } from "@angular/service-worker";
import { BehaviorSubject } from "rxjs";
import { UnsubscribeOnDestroy } from "../unsubscribe-on-destroy";
import { ApmService } from "../../core/services/apm.service";
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "../../core/services/apm.service";
var ServiceWorkerService = /** @class */ (function (_super) {
    __extends(ServiceWorkerService, _super);
    function ServiceWorkerService(swUpdate, apmService) {
        var _this = _super.call(this) || this;
        _this.swUpdate = swUpdate;
        _this.apmService = apmService;
        _this.updateMessage = new BehaviorSubject(undefined);
        if (swUpdate.isEnabled) {
            _this.subs.push(swUpdate.available.subscribe(function (event) {
                _this.updateMessage.next("App Updated");
            }));
        }
        return _this;
    }
    Object.defineProperty(ServiceWorkerService.prototype, "updateMessage$", {
        get: function () {
            return this.updateMessage.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    ServiceWorkerService.onMessage = function (type, on) {
        navigator.serviceWorker.addEventListener('message', function handler(event) {
            if (event.data.type === type) {
                if (!!event.data.data) {
                    on(JSON.parse(event.data.data));
                }
                else {
                    on();
                }
            }
        });
    };
    ServiceWorkerService.sendMessage = function (type, data) {
        if (!!navigator.serviceWorker) {
            if (!!data) {
                navigator.serviceWorker.controller.postMessage({ type: type, data: JSON.stringify(data) });
            }
            else {
                navigator.serviceWorker.controller.postMessage({ type: type });
            }
        }
    };
    ServiceWorkerService.serviceWorker = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!environment.production) return [3 /*break*/, 2];
                        return [4 /*yield*/, navigator.serviceWorker.register('/sw-sync.js')];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, navigator.serviceWorker.register('/sw-master.js')];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/, navigator.serviceWorker.ready];
                }
            });
        });
    };
    ServiceWorkerService.unRegister = function () {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
            for (var _i = 0, registrations_1 = registrations; _i < registrations_1.length; _i++) {
                var registration = registrations_1[_i];
                registration.unregister();
            }
        });
    };
    ServiceWorkerService.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ServiceWorkerService.serviceWorker()];
                    case 1:
                        _a.sent();
                        ServiceWorkerService.onMessage('error', function (error) {
                            _this.onError(error);
                        });
                        ServiceWorkerService.onMessage('version', function (version) {
                            _this.apmService.putContext('serviceWorkerVersion', version);
                        });
                        ServiceWorkerService.sendMessage('version');
                        return [2 /*return*/];
                }
            });
        });
    };
    ServiceWorkerService.prototype.update = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.swUpdate.activateUpdate()];
                    case 1:
                        _a.sent();
                        this.updateMessage.next(undefined);
                        window.location.reload();
                        return [2 /*return*/];
                }
            });
        });
    };
    ServiceWorkerService.prototype.onError = function (error) {
        console.error(error);
        this.apmService.error(error);
    };
    ServiceWorkerService.ngInjectableDef = i0.defineInjectable({ factory: function ServiceWorkerService_Factory() { return new ServiceWorkerService(i0.inject(i1.SwUpdate), i0.inject(i2.ApmService)); }, token: ServiceWorkerService, providedIn: "root" });
    return ServiceWorkerService;
}(UnsubscribeOnDestroy));
export { ServiceWorkerService };
