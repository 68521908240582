var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { UnsubscribeOnDestroy } from "../unsubscribe-on-destroy";
import { ServiceWorkerService } from "./service-worker.service";
import { Subject, timer } from "rxjs";
import { OfflineService } from "./offline.service";
import { asPromise } from "../../../../../admin/src/app/util/util";
import { RemoteDbService } from "./database/remote-db.service";
import * as moment from "moment";
import { arrayRemoveIf } from "../../shared/util/util";
import { AuthService } from "../../core/services/auth.service";
import ClaimsUtil from "../../shared/util/claims.util";
import { filter } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./service-worker.service";
import * as i2 from "./offline.service";
import * as i3 from "./database/remote-db.service";
import * as i4 from "../../core/services/auth.service";
var SyncService = /** @class */ (function (_super) {
    __extends(SyncService, _super);
    function SyncService(serviceWorkerService, offlineService, remoteDbService, authService) {
        var _this = _super.call(this) || this;
        _this.serviceWorkerService = serviceWorkerService;
        _this.offlineService = offlineService;
        _this.remoteDbService = remoteDbService;
        _this.authService = authService;
        _this.onlineSubs = [];
        _this.syncRequests = [];
        _this.tableConfigs = [
            {
                table: 'public',
                path: 'public',
                singleId: 'public',
                syncOnce: true
            },
            {
                table: 'applicationSettings',
                path: 'admin/devicePermissions',
                singleId: 'devicePermissions',
                syncOnce: true
            },
            {
                table: 'devices',
                path: 'devices/${deviceId}',
                singleId: '${deviceId}'
            },
            {
                table: 'records',
                path: 'data/${deviceId}/records'
            },
            {
                table: 'alertStates',
                path: 'state/${deviceId}/alertStates'
            },
            {
                table: 'fanSpeeds',
                path: 'state/${deviceId}/fanSpeeds'
            },
            {
                table: 'formSubmissions',
                path: 'data/${deviceId}/formSubmissions',
                permission: 'barcodes-read'
            },
            {
                table: 'barcodes',
                path: 'data/${deviceId}/barcodes',
                permission: 'barcodes-read'
            }
        ];
        _this._synced$ = new Subject();
        ServiceWorkerService.onMessage('synced', function (data) { return _this.onSynced(data); });
        return _this;
    }
    Object.defineProperty(SyncService.prototype, "synced$", {
        get: function () {
            return this._synced$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    SyncService.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        if (!!this.onlineSub) {
            this.onlineSub.unsubscribe();
        }
        this.onlineSubs.forEach(function (a) { return a.unsubscribe(); });
        this.onlineSubs = [];
    };
    SyncService.prototype.sync = function (deviceId, tableName) {
        return __awaiter(this, void 0, void 0, function () {
            var offline, tableConfig, user, syncRequest, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, asPromise(this.offlineService.offline$)];
                    case 1:
                        offline = _b.sent();
                        if (offline) {
                            return [2 /*return*/];
                        }
                        tableConfig = this.tableConfigs.find(function (a) { return a.table === tableName; });
                        if (!!!tableConfig.permission) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.authService.user()];
                    case 2:
                        user = _b.sent();
                        if (!ClaimsUtil.hasAnyPermission(user.claims, deviceId, [tableConfig.permission])) {
                            return [2 /*return*/];
                        }
                        _b.label = 3;
                    case 3:
                        if (!tableConfig) {
                            throw new Error('Missing table config for table: ' + tableName);
                        }
                        tableConfig = __assign({}, tableConfig);
                        tableConfig.path = tableConfig.path.replace('${deviceId}', deviceId);
                        if (!!tableConfig.singleId) {
                            tableConfig.singleId = tableConfig.singleId.replace('${deviceId}', deviceId);
                        }
                        _a = {
                            id: RemoteDbService.createId(),
                            deviceId: deviceId,
                            created: moment().unix(),
                            tableConfig: tableConfig
                        };
                        return [4 /*yield*/, this.authService.user()];
                    case 4:
                        syncRequest = (_a.user = _b.sent(),
                            _a);
                        this.syncRequests.push(syncRequest);
                        ServiceWorkerService.sendMessage('sync', syncRequest);
                        return [2 /*return*/];
                }
            });
        });
    };
    SyncService.prototype.syncAll = function (deviceId) {
        return __awaiter(this, void 0, void 0, function () {
            var user, _i, _a, deviceId_1;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!deviceId) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.authService.user()];
                    case 1:
                        user = _b.sent();
                        _i = 0, _a = ClaimsUtil.deviceIds(user.claims);
                        _b.label = 2;
                    case 2:
                        if (!(_i < _a.length)) return [3 /*break*/, 5];
                        deviceId_1 = _a[_i];
                        return [4 /*yield*/, this.syncAll(deviceId_1)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                    case 6:
                        this.subs.push(this.offlineService.offline$
                            .pipe(filter(function (offline) { return !offline; }))
                            .subscribe(function () { return __awaiter(_this, void 0, void 0, function () {
                            var _i, _a, tableConfig;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _i = 0, _a = this.tableConfigs;
                                        _b.label = 1;
                                    case 1:
                                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                                        tableConfig = _a[_i];
                                        return [4 /*yield*/, this.sync(deviceId, tableConfig.table)];
                                    case 2:
                                        _b.sent();
                                        _b.label = 3;
                                    case 3:
                                        _i++;
                                        return [3 /*break*/, 1];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); }));
                        this.subs.push(timer(30000, 30000).subscribe(function () { return __awaiter(_this, void 0, void 0, function () {
                            var _i, _a, tableConfig;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _i = 0, _a = this.tableConfigs.filter(function (a) { return !a.syncOnce; });
                                        _b.label = 1;
                                    case 1:
                                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                                        tableConfig = _a[_i];
                                        return [4 /*yield*/, this.sync(deviceId, tableConfig.table)];
                                    case 2:
                                        _b.sent();
                                        _b.label = 3;
                                    case 3:
                                        _i++;
                                        return [3 /*break*/, 1];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); }));
                        return [2 /*return*/];
                }
            });
        });
    };
    SyncService.prototype.clearSyncRequests = function (tableName) {
        arrayRemoveIf(this.syncRequests, function (a) { return a.tableConfig.table === tableName; });
    };
    SyncService.prototype.isActiveSyncRequest = function (tableName) {
        return this.syncRequests.some(function (a) { return a.tableConfig.table === tableName; });
    };
    SyncService.prototype.onSynced = function (syncResults) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._synced$.next(syncResults);
                        arrayRemoveIf(this.syncRequests, function (a) { return a.id === syncResults.id; });
                        if (!this.isActiveSyncRequest(syncResults.tableName)) return [3 /*break*/, 2];
                        this.clearSyncRequests(syncResults.tableName);
                        return [4 /*yield*/, this.sync(syncResults.deviceId, syncResults.tableName)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    SyncService.ngInjectableDef = i0.defineInjectable({ factory: function SyncService_Factory() { return new SyncService(i0.inject(i1.ServiceWorkerService), i0.inject(i2.OfflineService), i0.inject(i3.RemoteDbService), i0.inject(i4.AuthService)); }, token: SyncService, providedIn: "root" });
    return SyncService;
}(UnsubscribeOnDestroy));
export { SyncService };
