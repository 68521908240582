import {Injectable} from '@angular/core';
import {AngularFireDatabase, SnapshotAction} from "@angular/fire/database";
import {asPromise} from "../../../shared/util/rxjs.util";
import {Observable} from "rxjs";
import IModel from "../../../../../../database/models/i-model";
import PushIdGenerator from "../../../shared/util/push-id-generator";
import * as momentTz from "moment-timezone";
import {debounceTime, map, skip} from "rxjs/operators";
import {shallowClone} from "../../../shared/util/util";


@Injectable({
  providedIn: 'root'
})
export class RemoteDbService {

  constructor(
    private firedb: AngularFireDatabase
  ) {
    momentTz.tz.setDefault("Africa/Johannesburg")
  }

  static createId() {
    return PushIdGenerator.generate();
  }

  static async mapVals<T extends IModel>(promise: Promise<SnapshotAction<T>[]>) {
    const snapshots = await promise;
    if (!snapshots) {
      return null;
    }
    return snapshots.map(s => RemoteDbService.val<T>(s));
  }

  static pushChild<T extends IModel>(parent, child: T): T {
    const id: string = RemoteDbService.createId();
    child.$id = id;
    parent[id] = child;
    return child;
  }

  static setChild<T extends IModel>(parent, child: T): T {
    const id = child.$id;
    parent[id] = child;
    return child;
  }

  static updateChild<T extends IModel>(parent, child: T): T {
    const id = child.$id;
    const before = parent[id];
    const after = {...before, child};
    parent[id] = after;
    return after;
  }

  static val<T extends IModel>(snapshot: SnapshotAction<T>): T {
    if (!snapshot.payload.exists()) {
      return null;
    }

    const value = snapshot.payload.val();
    value.$id = snapshot.key;
    return value;
  }

  static vals<T extends IModel>(snapshots: SnapshotAction<T>[]): T[] {
    return snapshots.map(snapshot => RemoteDbService.val<T>(snapshot))
  }

  getSnapshot$<T>(path: string): Observable<SnapshotAction<T>> {
    return this.firedb.object<T>(path).snapshotChanges().pipe();
  }

  async list<T extends IModel>(path: string): Promise<T[]> {
    return RemoteDbService.mapVals<T>(asPromise(this.firedb.list<T>(path).snapshotChanges()));
  }

  list$<T>(path: string): Observable<T[]> {
    return this.firedb.list<T>(path).snapshotChanges()
      .pipe(
        map(snapshots => snapshots.map(snapshot => RemoteDbService.val<T>(snapshot)))
      );
  }

  async listSince<T extends IModel>(path: string, orderBy: string, since: number): Promise<T[]> {

    let angularFireList;

    if (!!since) {
      angularFireList = this.firedb.list<T>(path,
        ref => ref.orderByChild(orderBy).startAt(since)
      );
    } else {
      angularFireList = this.firedb.list<T>(path);
    }

    return RemoteDbService.mapVals<T>(asPromise(angularFireList.snapshotChanges()));
  }

  listTouched$(path: string): Observable<boolean> {
    return this.firedb.list(path).stateChanges()
      .pipe(
        skip(1),
        map(() => true)
      )
  }

  async mapVal<T extends IModel>(promise: Promise<SnapshotAction<T>>): Promise<T> {
    return promise.then(snapshot => RemoteDbService.val<T>(snapshot));
  }

  async object<T extends IModel>(path: string): Promise<T> {
    return this.mapVal<T>(asPromise(this.firedb.object<T>(path).snapshotChanges()));
  }

  object$<T>(path: string): Observable<T> {

    return this.firedb.object<T>(path).snapshotChanges().pipe(
      map(snapshot => RemoteDbService.val<T>(snapshot))
    )
  }

  objectTouched$(path: string): Observable<boolean> {
    return this.firedb.object(path).valueChanges()
      .pipe(
        debounceTime(1000),
        map(() => true),
        skip(1)
      )
  }

  async remove(path: string): Promise<void> {
    await this.firedb.object(path).remove();
  }

  async set(path: string, value: IModel): Promise<IModel> {
    await this.firedb.object(path).set(this.clean(value));
    return value;
  }

  async update(path: string, partial: any): Promise<IModel> {
    await this.firedb.object(path).update(this.clean(partial));
    return this.object(path);
  }

  private clean(model) {
    return this.cleanRef(shallowClone(model));
  }

  private cleanRef(value) {
    switch (typeof value) {
      case "object":

        if (!value) {
          return undefined;
        }

        Object.keys(value).forEach(key => {
          if (key.startsWith('$')) {
            value[key] = undefined;
          } else {

            value[key] = this.clean(value[key]);
          }

          if (typeof value[key] === 'undefined') {
            delete value[key];
          }
        });
        break;
      case "number":
        if (isNaN(value)) {
          value = undefined;
        }
        break;
      case "function":
        value = undefined;
        break
    }

    return value;
  }
}
