import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {init as initApm} from 'elastic-apm-js-base'
import Device from "../../../../../database/models/device";
import {DeviceDetectorService} from "ngx-device-detector";
import {Subject} from "rxjs";
import {bufferTime} from "rxjs/operators";
import {UnsubscribeOnDestroy} from "../../protected/unsubscribe-on-destroy";

declare var gtag: any;
declare var ga: any;

export interface CustomMetric {
  action: string;
  device?: Device
  label?: string,
  tags?: { [key: string]: string },
  value?: any,
}

@Injectable({
  providedIn: 'root'
})
export class ApmService extends UnsubscribeOnDestroy {

  private apm;
  private console$: Subject<any[]> = new Subject();
  private tags: { [key: string]: string } = {};

  constructor(
    private deviceService: DeviceDetectorService
  ) {
    super();
  }

  error(e: Error) {
    console.error(e);
    this.apm.captureError(e);
  }

  init() {
    this.apm = initApm({
      // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
      serviceName: 'gnarus-app',
      // Set custom APM Server URL (default: http://localhost:8200)
      serverUrl: 'https://5e8d2ed513b7486b9fcef6eb7a27efdc.apm.us-east-1.aws.cloud.es.io:443',
      serviceVersion: environment.version
    });

    this.tags.appVersion = environment.version;
    this.tags.environment = environment.production ? 'production' : 'development';
    this.tags.environment = environment.production ? 'production' : 'development';

    this.tags = {
      ...this.tags,
      ...this.deviceService.getDeviceInfo(),
    };

    if (this.deviceService.isDesktop()) {
      this.tags.deviceType = 'desktop';
    }
    if (this.deviceService.isMobile()) {
      this.tags.deviceType = 'mobile';
    }
    if (this.deviceService.isTablet()) {
      this.tags.deviceType = 'tablet';
    }
  }

  metric(metric: CustomMetric) {

    gtag('event', metric.action, {
      'event_category': 'metric',
      'event_label': metric.label,
      'value': metric.value
    });

    let tags = metric.tags;

    if (!!metric.device) {
      if (!tags) {
        tags = {};
      }
      tags.deviceId = metric.device.$id;
      tags.deviceName = metric.device.settings.name;
    }

    if (!tags) {
      tags = {};
    }

    tags.value = metric.value;

    const transaction = this.apm.startTransaction(metric.label, metric.action);
    const span = transaction.startSpan(metric.label, metric.action);
    span.addTags({...this.tags, ...tags});
    span.end();
    transaction.end();

    const newMetric = {...metric};
    delete newMetric.device;
    delete newMetric.tags;
    console.debug(`(APM) Send metric: ${JSON.stringify(newMetric)}`);

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  putContext(key: string, value: string) {

    const tag = {};
    tag[key] = value;
    this.tags = {...this.tags, ...tag};
    this.apm.setCustomContext(this.tags);
    console.debug(`(APM) Set context: ${JSON.stringify(tag)}`);
  }

  setUser(uid: string, fullName?: string, email?: string) {
    console.debug(`(APM) Set user: ${uid}, ${fullName}, ${email}`);
    gtag('set', {'user_id': uid});
    ga('set', 'userId', uid);
    this.tags = {
      ...this.tags, ...{
        uid: uid,
        fullName: fullName
      }
    };
    this.apm.setUserContext({
      id: uid,
      username: fullName,
      email: email
    })
  }
}
