import {Injectable} from '@angular/core';
import User from "../../../../../database/models/user";
import {LocalDbService} from "../services/database/local-db.service";
import AbstractRepository from "./abstract.repository";

@Injectable({
  providedIn: 'root'
})
export class UserRepository extends AbstractRepository<User> {

  constructor(localdb: LocalDbService,) {
    super(localdb, 'users');
  }
}
