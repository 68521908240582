import {Injectable, OnDestroy} from '@angular/core';
import {DexieService} from "./dexie.service";
import IModel from "../../../../../../database/models/i-model";
import {UnsubscribeOnDestroy} from "../../unsubscribe-on-destroy";
import * as moment from "moment";
import {IndexableType} from "dexie";

export interface FilterType {
  [index: string]: IndexableType
}

@Injectable({
  providedIn: 'root'
})
export class LocalDbService extends UnsubscribeOnDestroy implements OnDestroy {

  constructor(
    private dexieService: DexieService
  ) {
    super();
  }

  async between<T extends IModel>(tableName: string, orderBy: string, start?: IndexableType, end?: IndexableType): Promise<T[]> {

    if (!start && !end) {
      return await this.list<T>(tableName, null, orderBy);
    }

    const where = this.dexieService.table(tableName).where(orderBy);

    let collection;

    if (!start) {
      collection = where.below(end);
    } else if (!end) {
      collection = where.above(start);
    } else {
      collection = where.between(start, end, false, true);
    }

    return await collection.filter(m => !m.deleted).toArray();
  }

  async bulkPut<T extends IModel>(tableName: string, values: T[]): Promise<void> {
    if (values.some(value => !value.$id)) {
      throw new Error(`Attempt to save to table ${tableName} without $id`);
    }
    await this.dexieService.table<T>(tableName).bulkPut(values);
  }

  async bulkRemove(tableName: string, ids: string[]): Promise<void> {
    await this.dexieService.table(tableName).bulkDelete(ids);
  }

  async clear(tableName: string) {
    await this.dexieService.table(tableName).clear();
  }

  async delete<T extends IModel>(tableName: string, id: string): Promise<void> {

    await this.update(tableName, {$id: id, deleted: moment().unix()});
  }

  async exists(tableName: string, key: string): Promise<boolean> {
    return await !!this.get(tableName, key);
  }

  async first<T extends IModel>(tableName: string, orderBy: string, filter?: FilterType): Promise<T> {

    if (!filter) {
      return await this.dexieService.table(tableName).orderBy(orderBy).filter(m => !m.deleted).first();
    }

    const result = await this.list<T>(tableName, filter, orderBy);

    if (!result || result.length == 0) {
      return null;
    }

    return result[0];
  }

  async get<T extends IModel>(tableName: string, id: string): Promise<T> {

    if (!id) {
      throw new Error(`Cannot lookup by empty key for table ${tableName}`);
    }

    const model = await this.dexieService.table<T>(tableName).get(id);

    if (!!model && !!model.deleted) {
      return null;
    }

    return model;
  }

  async last<T extends IModel>(tableName: string, orderBy?: string, filter?: FilterType): Promise<T> {

    if (!filter) {
      return await this.dexieService.table(tableName).orderBy(orderBy).filter(m => !m.deleted).last();
    }

    const result = await this.list<T>(tableName, filter, orderBy);

    if (!result || result.length == 0) {
      return null;
    }

    return result[result.length - 1];
  }

  async list<T extends IModel>(tableName: string, filter?: FilterType, orderBy?: string, includeDeleted?: boolean): Promise<T[]> {

    if (!filter && !orderBy) {
      return await this.dexieService.table(tableName).filter(m => (includeDeleted || !m.deleted)).toArray();
    }

    if (!orderBy) {
      return await this.dexieService.table(tableName).where(filter).filter(m => (includeDeleted || !m.deleted)).toArray();
    }

    if (!filter) {
      return await this.dexieService.table(tableName).orderBy(orderBy).filter(m => (includeDeleted || !m.deleted)).toArray();
    }

    return await this.dexieService.table(tableName).where(filter).filter(m => !!m[orderBy] && (includeDeleted || !m.deleted)).sortBy(orderBy);
  }

  async listSortFirst<T extends IModel>(tableName: string, filter: (m: T) => boolean, orderBy: string): Promise<T[]> {

    return await this.dexieService.table(tableName).orderBy(orderBy).filter(m => filter(m) && !m.deleted).toArray();
  }


  async put<T extends IModel>(tableName: string, value: T): Promise<void> {

    if (!value.$id) {
      throw new Error(`Attempt to save to table ${tableName} without $id`);
    }

    await this.dexieService.table<T>(tableName).put(value);
  }

  async remove(tableName: string, id: string): Promise<void> {

    return await this.dexieService.table(tableName).delete(id);
  }


  async update<T extends IModel>(tableName: string, partial: T): Promise<void> {

    if (!partial.$id) {
      throw new Error(`Attempt to save to table ${tableName} without $id`);
    }

    await this.dexieService.table<T>(tableName).update(partial.$id, partial);
  }


}
