const backend: string = 'prod';

export const firebaseEnvironment = backend === 'prod' ? {
    apiKey: 'AIzaSyDQBrUvG0Re0oTq0ng7tGjZrE1zV34PzyM',
    authDomain: 'cognosc-c4581.firebaseapp.com',
    databaseURL: 'https://cognosc-c4581.firebaseio.com',
    projectId: 'cognosc-c4581',
    storageBucket: 'cognosc-c4581.appspot.com',
    messagingSenderId: '197488241391'
  } :
  {
    apiKey: 'AIzaSyDXAnwTSWPZHAPc4gZagfoHUick7UqUOgQ',
    authDomain: 'cognosc-test.firebaseapp.com',
    databaseURL: 'https://cognosc-test.firebaseio.com',
    projectId: 'cognosc-test',
    storageBucket: 'cognosc-test.appspot.com',
    messagingSenderId: '485870220480'
  };
