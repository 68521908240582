var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AngularFireDatabase } from "@angular/fire/database";
import { asPromise } from "../../../shared/util/rxjs.util";
import PushIdGenerator from "../../../shared/util/push-id-generator";
import * as momentTz from "moment-timezone";
import { debounceTime, map, skip } from "rxjs/operators";
import { shallowClone } from "../../../shared/util/util";
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/database";
var RemoteDbService = /** @class */ (function () {
    function RemoteDbService(firedb) {
        this.firedb = firedb;
        momentTz.tz.setDefault("Africa/Johannesburg");
    }
    RemoteDbService.createId = function () {
        return PushIdGenerator.generate();
    };
    RemoteDbService.mapVals = function (promise) {
        return __awaiter(this, void 0, void 0, function () {
            var snapshots;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, promise];
                    case 1:
                        snapshots = _a.sent();
                        if (!snapshots) {
                            return [2 /*return*/, null];
                        }
                        return [2 /*return*/, snapshots.map(function (s) { return RemoteDbService.val(s); })];
                }
            });
        });
    };
    RemoteDbService.pushChild = function (parent, child) {
        var id = RemoteDbService.createId();
        child.$id = id;
        parent[id] = child;
        return child;
    };
    RemoteDbService.setChild = function (parent, child) {
        var id = child.$id;
        parent[id] = child;
        return child;
    };
    RemoteDbService.updateChild = function (parent, child) {
        var id = child.$id;
        var before = parent[id];
        var after = __assign({}, before, { child: child });
        parent[id] = after;
        return after;
    };
    RemoteDbService.val = function (snapshot) {
        if (!snapshot.payload.exists()) {
            return null;
        }
        var value = snapshot.payload.val();
        value.$id = snapshot.key;
        return value;
    };
    RemoteDbService.vals = function (snapshots) {
        return snapshots.map(function (snapshot) { return RemoteDbService.val(snapshot); });
    };
    RemoteDbService.prototype.getSnapshot$ = function (path) {
        return this.firedb.object(path).snapshotChanges().pipe();
    };
    RemoteDbService.prototype.list = function (path) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, RemoteDbService.mapVals(asPromise(this.firedb.list(path).snapshotChanges()))];
            });
        });
    };
    RemoteDbService.prototype.list$ = function (path) {
        return this.firedb.list(path).snapshotChanges()
            .pipe(map(function (snapshots) { return snapshots.map(function (snapshot) { return RemoteDbService.val(snapshot); }); }));
    };
    RemoteDbService.prototype.listSince = function (path, orderBy, since) {
        return __awaiter(this, void 0, void 0, function () {
            var angularFireList;
            return __generator(this, function (_a) {
                if (!!since) {
                    angularFireList = this.firedb.list(path, function (ref) { return ref.orderByChild(orderBy).startAt(since); });
                }
                else {
                    angularFireList = this.firedb.list(path);
                }
                return [2 /*return*/, RemoteDbService.mapVals(asPromise(angularFireList.snapshotChanges()))];
            });
        });
    };
    RemoteDbService.prototype.listTouched$ = function (path) {
        return this.firedb.list(path).stateChanges()
            .pipe(skip(1), map(function () { return true; }));
    };
    RemoteDbService.prototype.mapVal = function (promise) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, promise.then(function (snapshot) { return RemoteDbService.val(snapshot); })];
            });
        });
    };
    RemoteDbService.prototype.object = function (path) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.mapVal(asPromise(this.firedb.object(path).snapshotChanges()))];
            });
        });
    };
    RemoteDbService.prototype.object$ = function (path) {
        return this.firedb.object(path).snapshotChanges().pipe(map(function (snapshot) { return RemoteDbService.val(snapshot); }));
    };
    RemoteDbService.prototype.objectTouched$ = function (path) {
        return this.firedb.object(path).valueChanges()
            .pipe(debounceTime(1000), map(function () { return true; }), skip(1));
    };
    RemoteDbService.prototype.remove = function (path) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.firedb.object(path).remove()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RemoteDbService.prototype.set = function (path, value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.firedb.object(path).set(this.clean(value))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, value];
                }
            });
        });
    };
    RemoteDbService.prototype.update = function (path, partial) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.firedb.object(path).update(this.clean(partial))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.object(path)];
                }
            });
        });
    };
    RemoteDbService.prototype.clean = function (model) {
        return this.cleanRef(shallowClone(model));
    };
    RemoteDbService.prototype.cleanRef = function (value) {
        var _this = this;
        switch (typeof value) {
            case "object":
                if (!value) {
                    return undefined;
                }
                Object.keys(value).forEach(function (key) {
                    if (key.startsWith('$')) {
                        value[key] = undefined;
                    }
                    else {
                        value[key] = _this.clean(value[key]);
                    }
                    if (typeof value[key] === 'undefined') {
                        delete value[key];
                    }
                });
                break;
            case "number":
                if (isNaN(value)) {
                    value = undefined;
                }
                break;
            case "function":
                value = undefined;
                break;
        }
        return value;
    };
    RemoteDbService.ngInjectableDef = i0.defineInjectable({ factory: function RemoteDbService_Factory() { return new RemoteDbService(i0.inject(i1.AngularFireDatabase)); }, token: RemoteDbService, providedIn: "root" });
    return RemoteDbService;
}());
export { RemoteDbService };
