var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { UnsubscribeOnDestroy } from "../unsubscribe-on-destroy";
import { RemoteDbService } from "../services/database/remote-db.service";
import * as moment from "moment";
var AbstractRepository = /** @class */ (function (_super) {
    __extends(AbstractRepository, _super);
    function AbstractRepository(localdb, tableName, syncService, options) {
        var _this = _super.call(this) || this;
        _this.localdb = localdb;
        _this.tableName = tableName;
        _this.syncService = syncService;
        _this.options = options;
        if (!options) {
            _this.options = {
                shouldUpload: false
            };
        }
        return _this;
    }
    AbstractRepository.prototype.bulkDelete = function (models) {
        return __awaiter(this, void 0, void 0, function () {
            var now, _i, models_1, model;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        now = moment().unix();
                        for (_i = 0, models_1 = models; _i < models_1.length; _i++) {
                            model = models_1[_i];
                            model.deleted = now;
                        }
                        return [4 /*yield*/, this.bulkSave(models)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AbstractRepository.prototype.bulkSave = function (models) {
        return __awaiter(this, void 0, void 0, function () {
            var now, _i, models_2, model;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        now = moment().unix();
                        for (_i = 0, models_2 = models; _i < models_2.length; _i++) {
                            model = models_2[_i];
                            if (!model.$id) {
                                model.$id = RemoteDbService.createId();
                            }
                            model.updated = now;
                            if (this.options.shouldUpload) {
                                model.$pendingUpload = now;
                            }
                        }
                        return [4 /*yield*/, this.localdb.bulkPut(this.tableName, models)];
                    case 1:
                        _a.sent();
                        this.checkSync(models);
                        return [2 /*return*/];
                }
            });
        });
    };
    AbstractRepository.prototype.byDeviceId = function (deviceId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.list({ deviceId: deviceId })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AbstractRepository.prototype.clear = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.localdb.clear(this.tableName)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AbstractRepository.prototype.delete = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var model;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.get(id)];
                    case 1:
                        model = _a.sent();
                        if (!model) {
                            return [2 /*return*/];
                        }
                        model.deleted = moment().unix();
                        return [4 /*yield*/, this.save(model)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AbstractRepository.prototype.exists = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.localdb.exists(this.tableName, id)];
            });
        });
    };
    AbstractRepository.prototype.first = function (filter, orderBy) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.localdb.first(this.tableName, orderBy, filter)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AbstractRepository.prototype.get = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.localdb.get(this.tableName, id)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AbstractRepository.prototype.last = function (filter, orderBy) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.localdb.last(this.tableName, orderBy, filter)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AbstractRepository.prototype.list = function (filter, orderBy) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.localdb.list(this.tableName, filter, orderBy)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AbstractRepository.prototype.remove = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.localdb.remove(this.tableName, id)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AbstractRepository.prototype.save = function (model) {
        return __awaiter(this, void 0, void 0, function () {
            var now;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!model.$id) {
                            model.$id = RemoteDbService.createId();
                        }
                        now = moment().unix();
                        model.updated = now;
                        if (this.options.shouldUpload) {
                            model.$pendingUpload = now;
                        }
                        return [4 /*yield*/, this.localdb.put(this.tableName, model)];
                    case 1:
                        _a.sent();
                        this.checkSync(model);
                        return [2 /*return*/];
                }
            });
        });
    };
    AbstractRepository.prototype.update = function (model) {
        return __awaiter(this, void 0, void 0, function () {
            var now, existing;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!model.$id) {
                            model.$id = RemoteDbService.createId();
                        }
                        now = moment().unix();
                        model.updated = now;
                        if (this.options.shouldUpload) {
                            model.$pendingUpload = now;
                        }
                        return [4 /*yield*/, this.exists(model.$id)];
                    case 1:
                        existing = _a.sent();
                        if (!!existing) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.localdb.put(this.tableName, model)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.localdb.update(this.tableName, model)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        this.checkSync(model);
                        return [2 /*return*/];
                }
            });
        });
    };
    AbstractRepository.prototype.checkSync = function (models) {
        return __awaiter(this, void 0, void 0, function () {
            var deviceIds, _loop_1, _i, models_3, model, _a, deviceIds_1, deviceId;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.options.shouldUpload) {
                            return [2 /*return*/];
                        }
                        if (!Array.isArray(models)) {
                            models = [models];
                        }
                        deviceIds = [];
                        _loop_1 = function (model) {
                            var deviceId = model.$deviceId;
                            if (!deviceId) {
                                // @ts-ignore
                                deviceId = model.deviceId;
                            }
                            if (!deviceId) {
                                return "continue";
                            }
                            if (!deviceIds.some(function (a) { return a === deviceId; })) {
                                deviceIds.push(deviceId);
                            }
                        };
                        for (_i = 0, models_3 = models; _i < models_3.length; _i++) {
                            model = models_3[_i];
                            _loop_1(model);
                        }
                        _a = 0, deviceIds_1 = deviceIds;
                        _b.label = 1;
                    case 1:
                        if (!(_a < deviceIds_1.length)) return [3 /*break*/, 4];
                        deviceId = deviceIds_1[_a];
                        return [4 /*yield*/, this.syncService.sync(deviceId, this.tableName)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        _a++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return AbstractRepository;
}(UnsubscribeOnDestroy));
export default AbstractRepository;
