import {Injectable} from "@angular/core";
import {UnsubscribeOnDestroy} from "../unsubscribe-on-destroy";
import {ServiceWorkerService} from "./service-worker.service";
import {Subject, Subscription, timer} from "rxjs";
import {OfflineService} from "./offline.service";
import {asPromise} from "../../../../../admin/src/app/util/util";
import {RemoteDbService} from "./database/remote-db.service";
import * as moment from "moment";
import {arrayRemoveIf} from "../../shared/util/util";
import User from "../../../../../database/models/user";
import {AuthService} from "../../core/services/auth.service";
import ClaimsUtil from "../../shared/util/claims.util";
import {filter} from "rxjs/operators";

interface TableConfig {
  path: string,
  permission?: string
  singleId?: string,
  syncOnce?: boolean
  table: string,
}

interface SyncRequest {
  created: number,
  deviceId: string,
  id: string,
  tableConfig: TableConfig,
  user: User
}

export interface SyncResults {
  deleted: string[]
  deviceId: string;
  fetched: string[];
  id: string;
  pushed: string[];
  tableName: string;
}

@Injectable({
  providedIn: 'root'
})
export class SyncService extends UnsubscribeOnDestroy {

  onlineSubs: Subscription[] = [];
  private onlineSub: Subscription;
  private syncRequests: SyncRequest[] = [];
  private tableConfigs: TableConfig[] = [
    {
      table: 'public',
      path: 'public',
      singleId: 'public',
      syncOnce: true
    },
    {
      table: 'applicationSettings',
      path: 'admin/devicePermissions',
      singleId: 'devicePermissions',
      syncOnce: true
    },
    {
      table: 'devices',
      path: 'devices/${deviceId}',
      singleId: '${deviceId}'
    },
    {
      table: 'records',
      path: 'data/${deviceId}/records'
    },
    {
      table: 'alertStates',
      path: 'state/${deviceId}/alertStates'
    },
    {
      table: 'fanSpeeds',
      path: 'state/${deviceId}/fanSpeeds'
    },
    {
      table: 'formSubmissions',
      path: 'data/${deviceId}/formSubmissions',
      permission: 'barcodes-read'
    },
    {
      table: 'barcodes',
      path: 'data/${deviceId}/barcodes',
      permission: 'barcodes-read'
    }
  ];

  constructor(
    private serviceWorkerService: ServiceWorkerService,
    private offlineService: OfflineService,
    private remoteDbService: RemoteDbService,
    private authService: AuthService
  ) {
    super();

    ServiceWorkerService.onMessage('synced', data => this.onSynced(data));
  }

  private _synced$ = new Subject<SyncResults>();

  get synced$() {
    return this._synced$.asObservable();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (!!this.onlineSub) {
      this.onlineSub.unsubscribe();
    }
    this.onlineSubs.forEach(a => a.unsubscribe());
    this.onlineSubs = [];
  }

  async sync(deviceId: string, tableName: string) {

    const offline = await asPromise(this.offlineService.offline$);

    if (offline) {
      return;
    }

    let tableConfig = this.tableConfigs.find(a => a.table === tableName);

    if (!!tableConfig.permission) {
      const user = await this.authService.user();
      if (!ClaimsUtil.hasAnyPermission(user.claims, deviceId, [tableConfig.permission])) {
        return;
      }
    }

    if (!tableConfig) {
      throw new Error('Missing table config for table: ' + tableName);
    }

    tableConfig = {...tableConfig};

    tableConfig.path = tableConfig.path.replace('${deviceId}', deviceId);

    if (!!tableConfig.singleId) {
      tableConfig.singleId = tableConfig.singleId.replace('${deviceId}', deviceId);
    }

    const syncRequest: SyncRequest = {
      id: RemoteDbService.createId(),
      deviceId: deviceId,
      created: moment().unix(),
      tableConfig: tableConfig,
      user: await this.authService.user()
    };

    this.syncRequests.push(syncRequest);

    ServiceWorkerService.sendMessage('sync', syncRequest);
  }

  async syncAll(deviceId?: string) {

    if (!deviceId) {
      const user = await this.authService.user();

      for (const deviceId of ClaimsUtil.deviceIds(user.claims)) {
        await this.syncAll(deviceId);
      }

      return;
    }

    this.subs.push(this.offlineService.offline$
      .pipe(
        filter(offline => !offline)
      )
      .subscribe(async () => {
        for (const tableConfig of this.tableConfigs) {
          await this.sync(deviceId, tableConfig.table);
        }
      })
    );

    this.subs.push(timer(30000, 30000).subscribe(async () => {
      for (const tableConfig of this.tableConfigs.filter(a => !a.syncOnce)) {
        await this.sync(deviceId, tableConfig.table);
      }
    }));
  }

  private clearSyncRequests(tableName: string) {
    arrayRemoveIf(this.syncRequests, a => a.tableConfig.table === tableName);
  }

  private isActiveSyncRequest(tableName: string) {
    return this.syncRequests.some(a => a.tableConfig.table === tableName);
  }

  private async onSynced(syncResults: SyncResults) {

    this._synced$.next(syncResults);

    arrayRemoveIf(this.syncRequests, a => a.id === syncResults.id);

    if (this.isActiveSyncRequest(syncResults.tableName)) {
      this.clearSyncRequests(syncResults.tableName);
      await this.sync(syncResults.deviceId, syncResults.tableName);
    }
  }
}
