import {UnsubscribeOnDestroy} from "../unsubscribe-on-destroy";
import {FilterType, LocalDbService} from "../services/database/local-db.service";
import {RemoteDbService} from "../services/database/remote-db.service";
import * as moment from "moment";
import IModel from "../../../../../database/models/i-model";
import {SyncService} from "../services/sync.service";

export default abstract class AbstractRepository<T extends IModel> extends UnsubscribeOnDestroy {

  cachedList: (tag: string) => Promise<T[]>;

  protected constructor(
    protected localdb: LocalDbService,
    protected tableName: string,
    protected syncService?: SyncService,
    protected options?: {
      shouldUpload: boolean
    }
  ) {
    super();

    if (!options) {
      this.options = {
        shouldUpload: false
      }
    }
  }

  async bulkDelete(models: T[]): Promise<void> {

    const now = moment().unix();

    for (const model of models) {
      model.deleted = now;
    }

    await this.bulkSave(models);
  }

  async bulkSave(models: T[]): Promise<void> {

    const now = moment().unix();

    for (const model of models) {

      if (!model.$id) {
        model.$id = RemoteDbService.createId();
      }

      model.updated = now;

      if (this.options.shouldUpload) {
        model.$pendingUpload = now;
      }
    }

    await this.localdb.bulkPut(this.tableName, models);
    this.checkSync(models);
  }

  async byDeviceId(deviceId: string): Promise<T[]> {

    return await this.list({deviceId: deviceId});
  }

  async clear() {
    await this.localdb.clear(this.tableName);
  }

  async delete(id: string): Promise<void> {
    const model = await this.get(id);

    if (!model) {
      return;
    }

    model.deleted = moment().unix();

    await this.save(model);
  }

  async exists(id: string): Promise<boolean> {

    return this.localdb.exists(this.tableName, id);
  }

  async first(filter?: FilterType, orderBy?: string): Promise<T> {

    return await this.localdb.first<T>(this.tableName, orderBy, filter);
  }

  async get(id?: string): Promise<T> {

    return await this.localdb.get<T>(this.tableName, id);
  }

  async last(filter?: FilterType, orderBy?: string): Promise<T> {

    return await this.localdb.last<T>(this.tableName, orderBy, filter);
  }

  async list(filter?: FilterType, orderBy?: string): Promise<T[]> {

    return await this.localdb.list<T>(this.tableName, filter, orderBy);
  }

  async remove(id: string): Promise<void> {

    await this.localdb.remove(this.tableName, id);
  }

  async save(model: T): Promise<void> {

    if (!model.$id) {
      model.$id = RemoteDbService.createId();
    }

    const now = moment().unix();

    model.updated = now;

    if (this.options.shouldUpload) {
      model.$pendingUpload = now;
    }

    await this.localdb.put(this.tableName, model);

    this.checkSync(model);
  }

  async update(model: T): Promise<void> {

    if (!model.$id) {
      model.$id = RemoteDbService.createId();
    }

    const now = moment().unix();

    model.updated = now;

    if (this.options.shouldUpload) {
      model.$pendingUpload = now;
    }

    const existing = await this.exists(model.$id);

    if (!existing) {
      await this.localdb.put(this.tableName, model);
    } else {
      await this.localdb.update(this.tableName, model);
    }

    this.checkSync(model);
  }

  protected async checkSync(models: T | T[]) {

    if (!this.options.shouldUpload) {
      return;
    }

    if (!Array.isArray(models)) {
      models = [models];
    }

    const deviceIds = [];

    for (const model of models) {

      let deviceId = model.$deviceId;

      if (!deviceId) {
        // @ts-ignore
        deviceId = model.deviceId;
      }

      if (!deviceId) {
        continue;
      }

      if (!deviceIds.some(a => a === deviceId)) {
        deviceIds.push(deviceId);
      }
    }

    for (const deviceId of deviceIds) {
      await this.syncService.sync(deviceId, this.tableName);
    }
  }
}
