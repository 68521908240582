import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {AngularFireDatabase} from "@angular/fire/database";
import {UnsubscribeOnDestroy} from "../unsubscribe-on-destroy";
import {distinctUntilChanged, filter} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class OfflineService extends UnsubscribeOnDestroy implements OnDestroy {
  constructor(
    private firedb: AngularFireDatabase
  ) {
    super();

    this.subs.push(
      this.firedb.object(".info/connected").valueChanges()
        .subscribe(connected => {
          this._offline$.next(!connected);
        })
    );
  }

  private _offline$ = new BehaviorSubject<boolean>(undefined);

  get offline$(): Observable<boolean> {
    return this._offline$.asObservable().pipe(
      filter(offline => offline != undefined)
    );
  }

  whenOnline<T>(subscriber: () => void) {
    this.subs.push(this.offline$.pipe(distinctUntilChanged(), filter(a => !a))
      .subscribe(() => subscriber()));
  }


}
