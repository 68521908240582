import {Injectable} from '@angular/core';
import Device from "../../../../../database/models/device";
import {LocalDbService} from "../services/database/local-db.service";
import AbstractRepository from "./abstract.repository";
import {asPromise} from "../../shared/util/rxjs.util";
import {AuthService} from "../../core/services/auth.service";
import ClaimsUtil from "../../shared/util/claims.util";
import Form from "../../../../../database/models/form";
import {SyncService} from "../services/sync.service";

@Injectable({
  providedIn: 'root'
})
export class DeviceRepository extends AbstractRepository<Device> {

  constructor(
    localdb: LocalDbService,
    syncService: SyncService,
    private authService: AuthService
  ) {
    super(localdb, "devices", syncService, {shouldUpload: true});
  }

  async list(): Promise<Device[]> {

    const user = await this.authService.user();

    const deviceIds = ClaimsUtil.deviceIds(user.claims);

    const list = await super.list();

    return list.filter(d => deviceIds.some(id => id === d.$id))
  }

  private enrichFormFields(forms: { [p: string]: Form }) {
    for (const form of Object.values(forms)) {
      for (const field of form.fields) {
        switch (field.type) {
          case "select":
            field.templateOptions.options.forEach((option, i) => {
              if (typeof option === "string") {
                field.templateOptions.options[i] = {
                  label: option,
                  value: option
                }
              }
              if (typeof option === "number") {
                field.templateOptions.options[i] = {
                  label: `${option}`,
                  value: option
                }
              }
            });
            if (field.templateOptions.sort) {
              field.templateOptions.options = field.templateOptions.options.sort((a, b) => a.label.localeCompare(b.label))
            }
        }
      }
    }
  }

  private setDeviceId(items: { [id: string]: any }, deviceId: string) {
    Object.values(items).forEach(i => i.deviceId = deviceId);
  }
}
