import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {

  forceReuseRoute = ['SensorsDashboardComponent'];
  preventReuseRoute = ['BarcodeComponent'];

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {

    let componentName = '';

    if (typeof curr.component === 'function') {
      componentName = curr.component.name;
    }

    if (typeof curr.component === 'string') {
      componentName = curr.component;
    }

    if (this.forceReuseRoute.some(c => c === componentName)) {
      return true;
    }

    if (this.preventReuseRoute.some(c => c === componentName)) {
      return false;
    }

    return future.routeConfig === curr.routeConfig;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
  }
}
